import React from 'react';
import { useFirestore, useFirebaseConnect } from 'react-redux-firebase';
import { engagements } from './constants';
import { useSelector } from 'react-redux';
import CardEngagementButton from './CardEngagementButton';
import FullEngagementButtons from '../EventDetails/FullEngagementButtons';

const EngagementButtonContainer = (props) => {
  const { event } = props;
  const { locationType, cardType } = props;
  const activeEventId = event?.eventId ?? null;
  const firestore = useFirestore();

  useFirebaseConnect([]);
  const profile = useSelector((state) => state.firebase?.profile);
  const uid = useSelector((state) => state.firebase?.auth?.uid);

  let goingEvents = profile?.events?.going ?? [];
  let interestedEvents = profile?.events?.interested ?? [];

  const getEngagementHandler = (type) => (toggleOff) => async () => {
    if (!uid) {
      return;
    } else if (!Object.values(engagements).includes(type)) {
      throw new Error(`Unrecognized type: ${type}`);
    }

    const eventRef = { collection: 'events', doc: activeEventId };
    const userRef = { collection: 'users', doc: uid };

    let userDocUpdate = {};
    let eventDocUpdate = {};

    const userUpdateFields = {
      [engagements.GOING]: 'events.going',
      [engagements.INTERESTED]: 'events.interested',
      [engagements.UNINTERESTED]: 'events.uninterested',
    };

    const eventUpdateFields = {
      [engagements.GOING]: 'goingUsers',
      [engagements.INTERESTED]: 'interestedUsers',
    };

    for (let engagementType in userUpdateFields) {
      const field = userUpdateFields[engagementType];
      if (toggleOff) {
        userDocUpdate[field] = firestore.FieldValue.arrayRemove(activeEventId);
      } else if (type === engagementType) {
        userDocUpdate[field] = firestore.FieldValue.arrayUnion(activeEventId);
      } else {
        userDocUpdate[field] = firestore.FieldValue.arrayRemove(activeEventId);
      }
    }

    for (let engagementType in eventUpdateFields) {
      const field = eventUpdateFields[engagementType];
      if (toggleOff) {
        eventDocUpdate[field] = firestore.FieldValue.arrayRemove(uid);
      } else if (type === engagementType) {
        eventDocUpdate[field] = firestore.FieldValue.arrayUnion(uid);
      } else {
        eventDocUpdate[field] = firestore.FieldValue.arrayRemove(uid);
      }
    }

    try {
      await firestore.update(userRef, userDocUpdate);
      await firestore.update(eventRef, eventDocUpdate);
    } catch (err) {
      console.error(err);
    }
  };

  const goingHandler = getEngagementHandler(engagements.GOING);
  const interestedHandler = getEngagementHandler(engagements.INTERESTED);

  const isGoing = goingEvents.includes(activeEventId);
  const isInterested = interestedEvents.includes(activeEventId);

  if (cardType === 'EventCard') {
    return (
      <CardEngagementButton
        interestedHandler={interestedHandler}
        goingHandler={goingHandler}
        isInterested={isInterested}
        isGoing={isGoing}
        uid={uid}
        type="EventCard"
        locationType={locationType}
      />
    );
  } else if (cardType === 'EventDetails') {
    return (
      <div>
        <FullEngagementButtons
          interestedHandler={interestedHandler}
          goingHandler={goingHandler}
          isInterested={isInterested}
          isGoing={isGoing}
          event={event}
          locationType={locationType}
        />
      </div>
    );
  }
};

export default EngagementButtonContainer;
