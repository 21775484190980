import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as ShareIcon } from './images/shareIcon.svg';
import { Button, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  eventDetails: {
    color: '#2F80ED',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '20px',
    height: '24px',
  },
  eventCard: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const ShareButton = (props) => {
  const { title, url, type, label, colorStroke } = props;
  const classes = useStyles();
  const onClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({ title, url });
        console.log('Shared!');
      } catch (err) {
        console.error('Share failed!', err.message);
      }
    } else {
      console.error('Web Share not supported!');
    }
  };

  const clickHandler = (e) => {
    e.stopPropagation();
    onClick();
  };

  if (type === 'eventDetails') {
    return (
      <Button
        className={classes.eventDetails}
        onClick={() => onClick()}
        startIcon={<ShareIcon stroke="#2F80ED" />}
      >
        Share Event
      </Button>
    );
  } else if (type === 'eventCard') {
    return (
      <IconButton
        className={classes.eventCard}
        style={{ padding: '0px' }}
        onClick={(event) => clickHandler(event)}
      >
        <ShareIcon stroke="white" />
      </IconButton>
    );
  } else {
    if (label) {
      return (
        <Button
          onClick={(event) => clickHandler(event)}
          startIcon={<ShareIcon stroke={colorStroke} />}
        >
          {label}
        </Button>
      );
    } else {
      return (
        <Button onClick={(event) => clickHandler(event)}>
          <ShareIcon stroke={colorStroke ? colorStroke : '#000000'} />
        </Button>
      );
    }
  }
};

export default ShareButton;
