import React from 'react';
import { generateUserSlices } from '../utils';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    margin: '0px',
    '& p': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#333333',
      margin: '0px',
    },
  },
}));

const innerTextDisplay = (firstNames) => [
  'Be the first of your friends to follow!',
  `${firstNames[0]}`,
  `${firstNames[0]} and ${firstNames[1]}`,
  `${firstNames[0]}, ${firstNames[1]}, and ${firstNames[2]}`,
  `${firstNames[0]}, ${firstNames[1]}, ${firstNames[2]}, +${
    firstNames.length - 3
  } others`,
];

const PeekFollowers = (props) => {
  const { org, profile, allUsers = [] } = props;
  const classes = useStyles();

  const followingUsers = org?.followers ?? [];

  const friends = profile?.friends;
  const isFriend = (uid) => friends?.includes(uid);
  const followingFriends = followingUsers.filter(isFriend);
  const friendsSlices = generateUserSlices(followingFriends, allUsers);

  const firstNames = friendsSlices.map((slice) => slice.firstName);
  const innerText = innerTextDisplay(firstNames)[followingFriends.length];

  const friendsAvatar = friendsSlices
    .sort((a, b) => {
      if (a.photoUrl && b.photoUrl) return 0;
      else if (a.photoUrl) return -1;
      else return 1;
    })
    .slice(0, 3)
    .map((slice) => {
      let initials = '';
      initials += slice?.firstName?.charAt(0);
      initials += slice?.lastName?.charAt(0);
      return (
        <Avatar
          key={slice.uid}
          src={slice.photoUrl}
          alt={`${slice.firstName}-${slice.lastName}-avatar`}
        >
          {initials}
        </Avatar>
      );
    });

  return (
    <div className={classes.root}>
      <div>
        <h2>{`${followingFriends.length} ${
          followingFriends.length === 1 ? 'Friend' : 'Friends'
        } Following`}</h2>
        <p>{innerText}</p>
      </div>
      <div style={{ marginBottom: '7px' }}>
        {friendsAvatar.length > 0 ? (
          <AvatarGroup spacing="small">{friendsAvatar}</AvatarGroup>
        ) : null}
      </div>
    </div>
  );
};

export default PeekFollowers;
