import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import 'firebase/functions';
import Tags from '../EventSubmission/Tags.js';

const EventTagging = () => {
  useFirestoreConnect([{ collection: 'events' }]);
  useFirestoreConnect([{ collection: 'meta', doc: 'tags' }]);
  const meta = useSelector((state) => state.firestore.data.meta);
  const tagList = meta?.tags.all;
  const events = useSelector((state) => state.firestore.ordered.events);
  if (!events) return null;
  return (
    <div>
      {events.map((e) => (
        <Event event={e} attributes={tagList} />
      ))}{' '}
    </div>
  );
};

const Event = (props) => {
  const firebase = useFirebase();
  const db = useFirestore();
  const { event } = props;
  var attributes = props.attributes;
  const [values, setValues] = useState({ tags: [] });
  if (event.tags && values.tags.length === 0) {
    setValues({ ...values, tags: event.tags });
  }
  const uploadTags = async (e) => {
    e.preventDefault();
    try {
      const docRefEvent = await db
        .collection('events')
        .doc(event.id)
        .update(values);
      const docRefTags = await db
        .collection('meta')
        .doc('tags')
        .update({ all: attributes });
      const storageRef = firebase.storage().ref();
      console.log(docRefEvent, docRefTags, storageRef);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div
      style={{
        textAlign: 'left',
        margin: '8px',
        padding: '24px',
        borderStyle: 'solid',
        borderColor: '#bababa',
        borderWidth: '1px',
        borderRadius: '5px',
      }}
    >
      <h3>
        {event.title} ({event.id})
      </h3>
      <h4>Address: {event.address}</h4>
      <h4>Host: {event.host}</h4>
      <img style={{ width: '600px' }} src={event.imageUrl} alt="Event Cover" />
      <p>{event.description}</p>
      <Tags
        metaTags={attributes}
        currentTags={values.tags}
        suggestions={[]}
        setTags={(tags) => setValues({ ...values, tags })}
      />
      <form className="form" onSubmit={uploadTags}>
        <Button
          style={{
            height: '50px',
            margin: '10px',
          }}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Update Tags
        </Button>
      </form>
    </div>
  );
};

export default EventTagging;
