import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { LINK_TYPES } from '../utils';
import Chip from '@material-ui/core/Chip';

const LINK_LOCATION_NAMES = {
  [LINK_TYPES.OBERLIN_WEBCAST]: 'Livestream',
  [LINK_TYPES.ZOOM_NONREGISTER]: 'Zoom',
  [LINK_TYPES.ZOOM_REGISTRATION]: 'Zoom',
  [LINK_TYPES.YOUTUBE]: 'Youtube',
};

const PendingEvent = (props) => {
  const db = useFirestore();
  const { pendingEvent } = props;
  let submittedTags = pendingEvent.tags;
  if (!pendingEvent.tags) {
    submittedTags = [];
  }
  const [values, setValues] = useState({
    tags: [],
    pendingTags: submittedTags,
    allTags: [],
  });

  const renderAddedTags = () => {
    let tagsToEnter = [];
    if (values.tags) {
      tagsToEnter = values.tags;
    }
    return tagsToEnter.map((tag) => {
      return (
        <Chip
          style={{
            margin: '5px',
          }}
          color="primary"
          label={tag}
        ></Chip>
      );
    });
  };
  const addToTags = (tag) => {
    let oldTags = values.tags;
    let newTags = oldTags.concat(tag);
    let pendingTags = Object.values(values.pendingTags);
    pendingTags.splice(pendingTags.indexOf(tag), 1);
    let newAll = Object.values(values.allTags).concat(tag);
    setValues({
      ...values,
      tags: newTags,
      pendingTags: pendingTags,
      allTags: newAll,
    });
  };

  const deleteTag = (tag) => {
    let pendingTags = Object.values(values.pendingTags);
    pendingTags.splice(pendingTags.indexOf(tag), 1);
    setValues({ ...values, pendingTags: pendingTags });
  };

  const renderPendingTags = () => {
    let tagsToSelect = [];
    if (values.pendingTags) {
      tagsToSelect = values.pendingTags;
    }
    return tagsToSelect.map((tag) => {
      return (
        <Chip
          style={{
            margin: '5px',
          }}
          color="secondary"
          label={tag}
          onClick={() => addToTags(tag)}
          onDelete={() => deleteTag(tag)}
        ></Chip>
      );
    });
  };

  useFirestoreConnect([{ collection: 'meta', doc: 'tags' }]);
  const meta = useSelector((state) => state.firestore.data.meta);
  let tagList = meta?.tags.all;
  if (!tagList) {
    tagList = [];
  }
  if (tagList.length > 0) {
    if (Object.values(values.allTags).length === 0) {
      setValues({ ...values, allTags: tagList });
    }
  }
  let pending = Object.values(values.pendingTags);
  let addedTags = values.tags;
  const filterTags = () => {
    for (let i = 0; i < values.pendingTags.length; i++) {
      const tag = values.pendingTags[i];
      if (tagList.indexOf(tag) !== -1) {
        addedTags = addedTags.concat(tag);
        pending.splice(pending.indexOf(tag), 1);
      }
    }
  };
  filterTags();
  if (addedTags !== values.tags) {
    setValues({ ...values, tags: addedTags, pendingTags: pending });
  }
  const handleReject = async () => {
    await db
      .collection('pendingEvents')
      .doc(pendingEvent.id)
      .update({ status: 'rejected' });
  };

  const handleAccept = async () => {
    const { 'location-type': locationType } = pendingEvent;
    const address =
      locationType === 'place'
        ? pendingEvent['location-address']
        : pendingEvent['location-url'];
    const passcode =
      locationType === 'place' ? null : pendingEvent['location-passcode'];
    let locationName;
    if (locationType === 'place') locationName = pendingEvent['location-name'];
    else if (LINK_LOCATION_NAMES[pendingEvent.linkType])
      locationName = LINK_LOCATION_NAMES[pendingEvent.linkType];
    else locationName = 'Web';

    if (values.pendingTags.length > 0) {
      alert('There are still pending Tags');
    } else {
      await db
        .collection('events')
        .doc(pendingEvent.id)
        .set({
          eventId: pendingEvent.id,
          title: pendingEvent.title,
          created: new Date(),
          location: {
            address,
            name: locationName,
            type: locationType,
            passcode: passcode,
          },
          imageUrl: pendingEvent.imageUrl,
          description: pendingEvent.description,
          filters: {
            attributes: pendingEvent.tags ? pendingEvent.tags : [],
          },
          time: {
            start: pendingEvent.startTime,
            end: pendingEvent.endTime,
          },
          manual: true,
          visibility: 'private',
          owner: {
            name: pendingEvent.host,
          },
        });
      await db
        .collection('pendingEvents')
        .doc(pendingEvent.id)
        .update({ status: 'accepted' });

      const tagSet = new Set(values.allTags);
      const allTagList = Array.from(tagSet);
      await db.collection('meta').doc('tags').update({ all: allTagList });

      console.log('uploaded');
    }
  };

  return (
    <div
      style={{
        textAlign: 'left',
        margin: '8px',
        padding: '24px',
        borderStyle: 'solid',
        borderColor: '#bababa',
        borderWidth: '1px',
        borderRadius: '5px',
      }}
    >
      <h3>
        {pendingEvent.title} ({pendingEvent.id})
      </h3>
      <h4>
        {new Date(pendingEvent.startTime.seconds * 1000).toString()} -{' '}
        {new Date(pendingEvent.endTime.seconds * 1000).toString()}
      </h4>
      <h4> Location Type: {pendingEvent['location-type']} </h4>
      {pendingEvent['location-type'] === 'web' ? (
        <React.Fragment>
          <h4>
            Link (type): {pendingEvent['location-url']} ({pendingEvent.linkType}
            )
          </h4>
          {pendingEvent['location-passcode'] ? (
            <h4>Passcode: {pendingEvent['location-passcode']}</h4>
          ) : null}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h4>
            Address: {pendingEvent['location-name']} |{' '}
            {pendingEvent['location-address']}
          </h4>
        </React.Fragment>
      )}
      <h4>Host: {pendingEvent.host}</h4>
      <img
        style={{ width: '600px' }}
        src={pendingEvent.imageUrl}
        alt="Event Cover"
      />
      <p>{pendingEvent.description}</p>
      <p> Added Tags: {renderAddedTags()} </p>
      <p> Pending Tags: {renderPendingTags()} </p>
      <p>Other Info: {pendingEvent.other}</p>
      <Button variant="contained" onClick={handleAccept} color="secondary">
        Accept
      </Button>
      <Button variant="contained" onClick={handleReject} color="primary">
        Reject
      </Button>
    </div>
  );
};

const EventVetting = () => {
  useFirestoreConnect([{ collection: 'pendingEvents' }]);
  const pendingEvents = useSelector(
    (state) => state.firestore.ordered.pendingEvents
  );
  if (!pendingEvents) return null;
  return (
    <div>
      {pendingEvents
        .filter((e) => e.status === 'pending')
        .map((e) => (
          <PendingEvent pendingEvent={e} />
        ))}{' '}
    </div>
  );
};

export default EventVetting;
