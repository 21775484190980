import React from 'react';
import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const makeEventDetailsHeader = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '240px',
    width: '1024px',
    color: 'white',
    borderRadius: '0px 0px 24px 24px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  vignette: {
    width: '100%',
    height: '100%',
    borderRadius: '0px 0px 24px 24px',
    background: `linear-gradient(180deg, rgba(51, 51, 51, 0.25) 0%, rgba(51, 51, 51, 0) 100%),
    linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0) 0.01%, rgba(51, 51, 51, 0.25) 100%)`,
  },
  eventTitle: {
    position: 'absolute',
    bottom: '24px',
    left: '86px',
    width: '427px',
    '& h1': {
      margin: '0px',
      marginTop: '8px',
      textAlign: 'left',
      fontSize: '32px',
      lineHeight: '36px',
      fontWeight: 'bold',
      textShadow: '0px 1px 8px rgba(0, 0, 0, 0.5)',
    },
  },
}));

export const JustifyContentCenterDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const makeEventDetailsPage = makeStyles((theme) => ({
  root: {
    '& h3': {
      marginTop: '24px',
      marginBottom: '8px',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '30px',
      letterSpacing: '0.15px',
    },
  },
  contentBox: {
    width: '1024px',
    textAlign: 'left',
  },
  content: {
    width: '470px',
    paddingLeft: '86px',
    '& p': {
      margin: `${theme.spacing(0.5)}px 0px 0px 0px`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '20px',
      '& a': {
        color: theme.palette.secondary.main,
      },
    },
  },
  engagement: {
    position: 'fixed',
    width: '331px',
    left: '57%',
    top: '203px',
    zIndex: '2',
  },
  engagementActive: {
    position: 'fixed',
    width: '331px',
    left: '57%',
    top: '85px',
    zIndex: '2',
  },

  footer: {
    height: '48px',
  },
}));

export const makeFullEngagementButtons = makeStyles(() => ({
  root: {
    width: '331px',
    height: '206px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 16px rgba(134, 134, 134, 0.25)',
    borderRadius: '8px',
    marginBottom: '24px',
    '& hr': {
      border: '1px solid #F2F2F2',
      width: '299px',
    },
  },
  timeAndLocation: {
    textAlign: 'left',
    padding: '16px 16px 8px 16px',
    fontWeight: '500',
    '& h2': {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: '500',
      letterSpacing: '0.15px',
      margin: '0px',
      marginBottom: '8px',
    },
    '& p': {
      fontSize: '14px',
      lineHeight: '20px',
      margin: '0px',
      '& strong': {
        color: '#C7003A',
      },
    },
  },
}));

export const StyledButton = styled(Button)({
  width: '142px',
  height: '64px',
  borderRadius: '4px',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  margin: '8px',
  textTransform: 'none',
  backgroundColor: (props) =>
    props.isAction && props.locationType === 'web'
      ? '#2F80ED'
      : props.isAction && props.locationType === 'place'
      ? '#F2C94C'
      : '#FFFFFF',
  '&.MuiButton-root:hover': {
    backgroundColor: (props) =>
      props.isAction && props.locationType === 'web'
        ? '#2F80ED'
        : props.isAction && props.locationType === 'place'
        ? '#F2C94C'
        : '#FFFFFF',
  },
});

export const StyledButtonDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  color: ${(props) =>
    props.isAction && props.locationType ? '#FFFFFF' : '#000000'};
`;

export const StyledGoingIcon = ({ isGoing, locationType }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke={isGoing && locationType ? '#FFFFFF' : '#000000'}
        strokeWidth="2"
      />
      <path
        d="M16 9L9.84615 15L8 13.2018"
        stroke={isGoing && locationType ? '#FFFFFF' : '#000000'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
