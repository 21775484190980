import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import TopNavBar from '../TopNavBar';
import CardCarousel from '../CardCarousel';
import { useFirebaseConnect, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { sortEventByStartTime } from '../utils';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import TagChip from '../shared/TagChip';
import { Link } from 'react-router-dom';
import EventCardCarouselSkeleton from '../shared/EventCardCarouselSkeleton';
import TagsSkeleton from '../shared/TagsSkeleton';

const useStyles = makeStyles(() => ({
  root: {},
  bigHeading: {
    textAlign: 'left',
    paddingLeft: '172px',
    fontSize: '32px',
    lineHeight: '36px',
    color: '#FF521E',
    marginTop: '48px',
    marginBottom: '0px',
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginLeft: '173px',
    marginRight: '185px',
    '& h2': {
      textAlign: 'left',
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '30px',
      letterSpacing: '0.15px',
      marginTop: '24px',
      marginBottom: '16px',
    },
  },
  moreButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: '#2F80ED',
    width: '33px',
    height: '20px',
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '24px',
    marginBottom: '16px',
    textTransform: 'none',
  },
  popularTags: {
    marginLeft: '173px',
    marginRight: '185px',
    textAlign: 'left',
    fontSize: '14px',
  },
  footer: {
    height: '48px',
  },
}));

const HomePage = (props) => {
  const classes = useStyles();

  useFirebaseConnect([]);
  const profile = useSelector((state) => state.firebase?.profile);
  useFirestoreConnect('events');
  const eventMap = useSelector((state) => state.firestore?.data?.events) ?? {};

  const [recEventIds, setRecEventIds] = useState([]);
  const [popEventIds, setPopEventIds] = useState([]);
  const [tags, setTags] = useState([]);
  const [otherEventCarousels, setOtherEventCarousels] = useState({});

  useEffect(() => {
    if (!profile.uid) return;
    const getEventCarousels = async () => {
      const recEventsCarousel = firebase
        .functions()
        .httpsCallable('getUserRecommendedEvents');
      const popularEventsCarousel = firebase
        .functions()
        .httpsCallable('getMostPopularEvents');
      const popularTags = firebase.functions().httpsCallable('getPopularTags');
      const otherCarousels = firebase
        .functions()
        .httpsCallable('getUserOtherCarousels');

      try {
        const recommended = await recEventsCarousel({ uid: profile.uid });
        setRecEventIds(recommended?.data ?? {});

        const popular = await popularEventsCarousel({ uid: profile.uid });
        setPopEventIds(popular?.data ?? {});

        const tags = await popularTags();
        setTags(tags?.data ?? []);

        const others = await otherCarousels({ uid: profile.uid });
        setOtherEventCarousels(others?.data ?? []);
      } catch (error) {
        console.error(error);
      }
    };
    getEventCarousels();
  }, [profile.uid]);

  const recommendedEvents = recEventIds.map((id) => eventMap[id]);
  sortEventByStartTime(recommendedEvents);
  const popularEvents = popEventIds.map((id) => eventMap[id]);
  sortEventByStartTime(popularEvents);

  const otherCarouselTitles = Object.keys(otherEventCarousels);

  return (
    <div className={classes.root}>
      <h1 className={classes.bigHeading}>Browse Events</h1>

      <section>
        <div className={classes.titleSection}>
          <h2>Recommended For You</h2>
        </div>
        {recommendedEvents.length === 0 ? (
          <EventCardCarouselSkeleton />
        ) : (
          <CardCarousel events={recommendedEvents} />
        )}
      </section>

      <section>
        <div className={classes.titleSection}>
          <h2>Most Popular Events</h2>
          {/* <Button className={classes.moreButton}>More</Button> */}
        </div>
        {popularEvents.length === 0 ? (
          <EventCardCarouselSkeleton />
        ) : (
          <CardCarousel events={popularEvents} />
        )}
      </section>

      <section>
        <div className={classes.titleSection}>
          <h2>Popular Tags</h2>
        </div>
        <div className={classes.popularTags}>
          {tags.length === 0 ? (
            <TagsSkeleton />
          ) : (
            tags.map((item) => (
              <Link
                to={`/search/{"tags":["${item}"]}`}
                style={{ textDecoration: 'none' }}
                key={item}
              >
                <TagChip
                  style={{
                    fontSize: '14px',
                    height: '36px',
                  }}
                  label={item}
                  //clickable="true"
                />
              </Link>
            ))
          )}
        </div>
      </section>

      {otherEventCarousels ? (
        <section>
          {otherCarouselTitles.map((title, idx) => (
            <div key={`${title}-${idx}`}>
              <div className={classes.titleSection}>
                <h2>{title}</h2>
              </div>
              <CardCarousel
                events={otherEventCarousels[title].map((id) => eventMap[id])}
              />
            </div>
          ))}
        </section>
      ) : null}

      <section className={classes.footer} />
    </div>
  );
};

export default HomePage;
