import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { ReactComponent as HeartIcon } from '../shared/images/heart.svg';
import { StyledGoingIcon, StyledHeartIcon } from './styledIcons';

const StyledCardButton = styled(Button)({
  position: 'absolute',
  borderRadius: '40px',
  top: '155px',
  bottom: '125px',
  right: '55px',
  height: '40px',
  minWidth: '40px',
  padding: '0px',
  zIndex: '1',
  boxShadow: '0px 1px 16px rgba(134, 134, 134, 0.25)',
  backgroundColor: (props) =>
    props.isAction && props.locationType === 'web'
      ? '#2F80ED'
      : props.isAction && props.locationType === 'place'
      ? '#F2C94C'
      : '#FFFFFF',
  '&.MuiButton-root:hover': {
    backgroundColor: (props) =>
      props.isAction && props.locationType === 'web'
        ? '#2F80ED'
        : props.isAction && props.locationType === 'place'
        ? '#F2C94C'
        : '#FFFFFF',
  },
});

const CardEngagementButton = (props) => {
  const { interestedHandler, goingHandler, isInterested, isGoing } = props;
  const { uid, type, locationType } = props;

  if (type === 'EventCard' && isGoing) {
    return (
      <StyledCardButton
        disableRipple={true}
        isAction={isGoing}
        locationType={locationType}
      >
        <StyledGoingIcon
          isGoing={isGoing}
          locationType={locationType}
          onClick={goingHandler(isGoing)}
        />
      </StyledCardButton>
    );
  } else if (type === 'EventCard' && !isGoing) {
    return (
      <StyledCardButton
        disableRipple={true}
        isAction={isInterested}
        locationType={locationType}
      >
        <HeartIcon
          stroke={isInterested && locationType ? '#FFFFFF' : '#000000'}
          fill="#FFFFFF"
          onClick={interestedHandler(isInterested)}
        />
      </StyledCardButton>
    );
  } else if (!isGoing) {
    return (
      <Button
        disableRipple={true}
        style={!uid ? { visibility: 'hidden' } : null}
      >
        <StyledHeartIcon
          isInterested={isInterested}
          locationType={locationType}
          onClick={interestedHandler(isInterested)}
        />
      </Button>
    );
  } else {
    return (
      <Button disableRipple={true}>
        <StyledGoingIcon
          isGoing={isGoing}
          locationType={locationType}
          onClick={goingHandler(isGoing)}
        />
      </Button>
    );
  }
};

export default CardEngagementButton;
