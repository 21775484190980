import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/functions';
import { createFirestoreInstance } from 'redux-firestore';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { BrowserRouter as Router } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

let fbConfig;

if (process.env.NODE_ENV === 'development') {
  fbConfig = require('./shared/config/fbConfigDev').default;
} else {
  fbConfig = require('./shared/config/fbConfig').default;
}
firebase.initializeApp(fbConfig);
firebase.firestore();
firebase.functions();

const App = require('./App').default;

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const theme = createMuiTheme({
  palette: {
    text: { main: '#000000' },
    primary: {
      main: '#ff521e',
    },
    secondary: {
      main: '#2f80ed',
      contrastText: '#ffffff',
    },
    accent: {
      main: '#c7003a',
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Router>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
