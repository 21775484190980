import React from 'react';
import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy';
import EventSubmission from './components/EventSubmission';
import Login from './components/Login';
import AuthRoute from './routes/AuthRoute';
import { connect } from 'react-redux';
import AdminRoute from './routes/AdminRoute';
import EventVetting from './components/admin/EventVetting';
import EventTagging from './components/admin/EventTagging';
import Admin from './components/admin';
import HomePage from './components/HomePage';
import EventDetails from './components/EventDetails';
import OrganizationDetails from './components/OrganizationDetails';
import MyProfile from './components/MyProfile';
import MyEvents from './components/MyEvents';
import MyInterests from './components/MyInterests';
import EditAcademicOrgs from './components/MyInterests/EditAcademicOrgs';
import EditStudentOrgs from './components/MyInterests/EditStudentOrgs';
import EditInterests from './components/MyInterests/EditInterests';
import SearchResults from './components/SearchResults';
import TopNavBar from './components/TopNavBar';

const App = (props) => {
  const { isLoggedIn, isAdmin, isLoaded } = props;
  if (!isLoaded) return null;
  return (
    <div className="App">
      <TopNavBar />
      <Switch>
        <Route path="/privacy" exact>
          <PrivacyPolicy />
        </Route>
        <Route path="/login" exact>
          {isLoggedIn ? <Redirect to="/" /> : null}
          <Login />
        </Route>
        <AuthRoute isLoggedIn={isLoggedIn} path="/" exact redirectBase="/login">
          <HomePage />
        </AuthRoute>
        <AuthRoute isLoggedIn={isLoggedIn} path="/events/:id" exact>
          <EventDetails isLoggedIn={isLoggedIn} />
        </AuthRoute>
        <AuthRoute
          isLoggedIn={isLoggedIn}
          path="/search/:query"
          exact
          redirectBase="/login"
        >
          <SearchResults />
        </AuthRoute>
        <AuthRoute isLoggedIn={isLoggedIn} path="/organizations/:id">
          <OrganizationDetails />
        </AuthRoute>
        <AuthRoute isLoggedIn={isLoggedIn} path="/profile" exact>
          <MyProfile />
        </AuthRoute>
        <AuthRoute isLoggedIn={isLoggedIn} path="/my-events" exact>
          <MyEvents />
        </AuthRoute>
        <AuthRoute isLoggedIn={isLoggedIn} path="/my-interests" exact>
          <MyInterests />
        </AuthRoute>
        <AuthRoute
          isLoggedIn={isLoggedIn}
          path="/profile/edit/academic-orgs"
          exact
          redirectBase="/my-interests"
        >
          <EditAcademicOrgs />
        </AuthRoute>
        <AuthRoute
          isLoggedIn={isLoggedIn}
          path="/profile/edit/student-orgs"
          exact
          redirectBase="/my-interests"
        >
          <EditStudentOrgs />
        </AuthRoute>
        <AuthRoute
          isLoggedIn={isLoggedIn}
          path="/profile/edit/tags"
          exact
          redirectBase="/my-interests"
        >
          <EditInterests />
        </AuthRoute>
        <AuthRoute
          isLoggedIn={isLoggedIn}
          path="/submit"
          exact
          redirectBase="/login"
        >
          <EventSubmission />
        </AuthRoute>
        <AdminRoute
          isAdmin={isAdmin}
          isLoggedIn={isLoggedIn}
          path="/admin"
          exact
          redirectBase="/login"
        >
          <Admin />
        </AdminRoute>
        {
          <AdminRoute
            isAdmin={isAdmin}
            isLoggedIn={isLoggedIn}
            path="/admin/event-vetting"
            exact
            redirectBase="/login"
          >
            <EventVetting />
          </AdminRoute>
        }
        <AdminRoute
          isAdmin={isAdmin}
          isLoggedIn={isLoggedIn}
          path="/admin/event-tagging"
          exact
          redirectBase="/login"
        >
          <EventTagging />
        </AdminRoute>
        <AdminRoute
          isAdmin={isAdmin}
          isLoggedIn={isLoggedIn}
          path="/admin/event-vetting"
          exact
          redirectBase="/login"
        >
          <EventVetting />
        </AdminRoute>
      </Switch>
    </div>
  );
};

export default connect(({ firebase }) => ({
  isAdmin: firebase.profile?.admin,
  isLoaded: firebase.auth.isLoaded,
  isLoggedIn: firebase.auth.uid ? true : false,
}))(App);
