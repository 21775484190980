import React from 'react';
import EventCardSkeleton from '../../assets/event-card-skeleton.svg';

const StyledEventCardSkeleton = () => {
  return (
    <object
      title="event card"
      type="image/svg+xml"
      data={EventCardSkeleton}
      style={{ paddingRight: '32px' }}
    />
  );
};

const EventCardCarouselSkeleton = () => (
  <div>
    <StyledEventCardSkeleton />
    <StyledEventCardSkeleton />
    <StyledEventCardSkeleton />
  </div>
);

export default EventCardCarouselSkeleton;
