import AuthRoute from './AuthRoute';
import React from 'react';
import { Route } from 'react-router-dom';

const AdminRoute = (props) => {
  const { isAdmin, isLoggedIn } = props;
  if (isLoggedIn) {
    if (isAdmin) return <Route {...props} />;
    else return <div>Oops! You don't have permissions to view this page</div>;
  }
  return <AuthRoute {...props} />;
};

export default AdminRoute;
