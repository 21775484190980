import React from 'react';
import Chip from '@material-ui/core/Chip';

const AddedTags = (props) => {
  const { fullSuggested, tagValues, setTagValues } = props;
  const { currentTags } = tagValues;

  const handleDelete = (tag) => {
    currentTags.splice(currentTags.indexOf(tag), 1);
    let updatedAddedFromSuggestions = tagValues.addedFromSuggestions;
    let newSuggested = tagValues.suggested;
    if (fullSuggested.indexOf(tag) >= 0) {
      updatedAddedFromSuggestions.splice(
        updatedAddedFromSuggestions.indexOf(tag),
        1
      );
      newSuggested.unshift(tag);
    }

    setTagValues({
      ...tagValues,
      currentTags,
      addedFromSuggestions: updatedAddedFromSuggestions,
      suggested: newSuggested,
    });
  };

  return currentTags.map((tag) => (
    <Chip
      style={{
        margin: '5px',
      }}
      key={tag}
      color="primary"
      label={tag}
      onDelete={() => handleDelete(tag)}
    ></Chip>
  ));
};

export default AddedTags;
