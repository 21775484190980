import React from 'react';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const TagInput = (props) => {
  const { tagValues, setTagValues, setTags } = props;

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      if (e.target.value !== '') {
        enterValue(e.target.value);
      }
    }
  };

  const handleTextChange = (event) => {
    setTagValues({
      ...tagValues,
      currentText: event.target.value,
    });
  };

  const enterValue = (val) => {
    const previousTags = tagValues.currentTags;
    let toBeAdded = val;
    const upperTags = tagValues.currentTags.map((x) => x.toUpperCase());
    const options = tagValues.optionList;
    const upperOptions = tagValues.optionList.map((x) => x.toUpperCase());
    if (options.indexOf(toBeAdded) === -1) {
      if (upperOptions.indexOf(toBeAdded.toUpperCase()) !== -1) {
        const optionIndex = upperOptions.indexOf(toBeAdded.toUpperCase());
        toBeAdded = options[optionIndex];
      }
    }
    if (
      upperTags.indexOf(toBeAdded.toUpperCase()) === -1 &&
      tagValues.currentTags.length < 5
    ) {
      const updatedTags = previousTags.concat(toBeAdded);
      const updatedFromBox = tagValues.addedFromBox.concat(toBeAdded);
      const updatedSuggestions = tagValues.suggested;
      const allCapsSuggestions = updatedSuggestions.map((x) => x.toUpperCase());
      if (allCapsSuggestions.indexOf(toBeAdded.toUpperCase()) !== -1) {
        updatedSuggestions.splice(updatedSuggestions.indexOf(toBeAdded), 1);
      }
      setTagValues({
        ...tagValues,
        currentTags: updatedTags,
        addedFromBox: updatedFromBox,
        currentText: '',
        suggestions: updatedSuggestions,
      });
      setTags(updatedTags);
    } else {
      setTagValues({
        ...tagValues,
        currentText: '',
        changed: true,
      });
    }
    setTags(tagValues.currentTags);
  };
  return (
    <div>
      <Autocomplete
        id="tags"
        options={tagValues.optionList}
        freeSolo
        onInputChange={(_e, val, reason) => {
          if (reason === 'reset' && val !== '') {
            enterValue(val);
          } else {
            setTagValues({ ...tagValues, currentText: val });
          }
        }}
        inputValue={tagValues.currentText}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            onKeyDown={(e) => {
              keyPress(e);
            }}
            onChange={handleTextChange}
            value={tagValues.currentText}
            variant="outlined"
            label="Tags"
            placeholder="Add Tags"
          />
        )}
      />
    </div>
  );
};

export default TagInput;
