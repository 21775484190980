import React from 'react';
import EventDateTime from '../shared/EventDateTime';
import { ReactComponent as HeartIcon } from '../shared/images/heart.svg';
import {
  makeFullEngagementButtons as useStyles,
  StyledButton,
  StyledButtonDiv,
  StyledGoingIcon,
} from './styles';

const FullEngagementButtons = (props) => {
  const {
    goingHandler,
    interestedHandler,
    isGoing,
    isInterested,
    event,
    locationType,
  } = props;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.timeAndLocation}>
        <h2>{`Time & Location`}</h2>
        <EventDateTime startTime={event?.time.start.toDate().toString()} />
        <p style={{ fontWeight: '400' }}>{event?.location?.name ?? ''}</p>
      </div>
      <div>
        <hr />
      </div>

      <StyledButton
        isAction={isInterested}
        locationType={locationType}
        disableRipple={true}
        onClick={interestedHandler(isInterested)}
      >
        <StyledButtonDiv isAction={isInterested} locationType={locationType}>
          <HeartIcon
            stroke={isInterested && locationType ? '#FFFFFF' : '#000000'}
            fill="#FFFFFF"
          />
          Interested
        </StyledButtonDiv>
      </StyledButton>

      <StyledButton
        disableRipple={true}
        isAction={isGoing}
        locationType={locationType}
        onClick={goingHandler(isGoing)}
      >
        <StyledButtonDiv isAction={isGoing} locationType={locationType}>
          <StyledGoingIcon isGoing={isGoing} locationType={locationType} />
          Going
        </StyledButtonDiv>
      </StyledButton>
    </div>
  );
};

export default FullEngagementButtons;
