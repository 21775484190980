/* eslint-disable no-useless-escape */

// CP: STOLEN and modified from https://github.com/TwineOrg/firebase-functions
// @ f8b14539c5a119c1fb90ee743f799dead31aac60

export const LINK_TYPES = {
  ZOOM_REGISTRATION: 'zoom_registration',
  ZOOM_NONREGISTER: 'zoom_nonregister',
  YOUTUBE: 'youtube',
  OBERLIN_WEBCAST: 'oberlin_webcast',
  WEB: 'web',
};

const parseNonZoomLinkType = (htmlStr) => {
  const regexpYoutube =
    /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)[^"\\\s]*/g;
  let resultArr = htmlStr.match(regexpYoutube);
  if (resultArr) {
    return LINK_TYPES.YOUTUBE;
  }

  const regexps = [
    // Webcasts
    /((?:https?:)?\/\/)?((?:www|m)\.)?((?:www.oberlin.edu\/conservatory\/on-stage\/live-webcasts\/))[^"\\\s]*/g,
    /((?:https?:)?\/\/)?((?:www|m)\.)?((?:www.oberlin.edu\/livestream\/))[^"\\\s]*/g,
  ];
  for (const regexp of regexps) {
    resultArr = htmlStr.match(regexp);
    if (resultArr) {
      return LINK_TYPES.OBERLIN_WEBCAST;
    }
  }
  return LINK_TYPES.WEB;
};

/**
 * Returns a link type from LINK_TYPES
 * @param {string} htmlStr
 */
export function getLinkType(htmlStr) {
  if (!htmlStr) return null;
  if (isZoomRegisterLink(htmlStr)) return LINK_TYPES.ZOOM_REGISTRATION;
  const regexpZoomLinkWithHash = /https:\/\/.{1,20}\.zoom\..*pwd=\w*/; // https:// \.zoom\. pwd=\w*
  const zoomLinkWithHashArr = htmlStr.match(regexpZoomLinkWithHash);
  const zoomLinkWithHash = zoomLinkWithHashArr?.[0];
  if (zoomLinkWithHash) return LINK_TYPES.ZOOM_NONREGISTER;

  const regexpZoomId =
    /\b[Zz]oom\b((?![Pp]ass(code|word)).){1,100}\d+([ -]\d+)*/g;
  // Match only the numbers of the end, as of 9/5/2020 look behind (?<=) is not supported on safari
  let zoomIdRawArr = htmlStr.match(regexpZoomId);
  const zoomIdMatches = zoomIdRawArr && zoomIdRawArr[0].match(/\d+([ -]\d+)*$/);
  let zoomId = zoomIdMatches?.[0];
  if (zoomId) return LINK_TYPES.ZOOM_NONREGISTER;
  else return parseNonZoomLinkType(htmlStr);
}

function isZoomRegisterLink(htmlStr) {
  const regexpZoomRegisterLink =
    /((?:https?:)?\/\/)?((?:www|m)\.)?((?:zoom)).*\/register\/([\w\-]+)[^"\\\s]*/g;
  const zoomRegisterLinkArr = htmlStr.match(regexpZoomRegisterLink);
  const zoomRegisterLink = zoomRegisterLinkArr?.[0];
  return zoomRegisterLink;
}

export const pseudoRandomGenerator = (seed) => {
  return () => {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };
};

export const BACKGROUND_COLORS = [
  '#f44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#FF9800',
  '#795548',
];

const dayInWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const dayInWeekAbbr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const monthInYear = [
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const dateTime = require('node-datetime');

export function getEventTimeString(startTimeInput, endTimeInput) {
  const startTime = new Date(startTimeInput);

  let startHourString = dateTime.create(startTime, 'I:M p').format();
  let startHour = startTime.getHours();
  if ((startHour > 0 && startHour < 10) || (startHour > 12 && startHour < 22)) {
    startHourString = startHourString.slice(1);
  } else if (startHour === 0) {
    startHourString = '';
  }

  let endTime;
  if (endTimeInput) {
    const endTimeUTC = new Date(endTimeInput);
    endTime = dateTime.create(endTimeUTC, 'I:M p').format();
  }
  endTime = endTime ? ` - ${endTime}` : '';

  return `${startHourString}${endTime}`;
}

export function getEventDateString(startTimeInput, abbr = false) {
  const startTime = new Date(startTimeInput);
  if (!startTime) return 'Time Unknown';

  let date;
  if (isToday(startTime)) {
    if (startTime.getHours() < 18) date = 'Today';
    else date = 'Tonight';
  } else if (isTomorrow(startTime)) {
    date = 'Tomorrow';
  } else if (abbr) {
    date = `
      ${dayInWeekAbbr[startTime.getDay()]},
      ${monthInYear[startTime.getMonth()]}
      ${startTime.getDate()}`;
  } else {
    date = `
      ${dayInWeek[startTime.getDay()]},
      ${monthInYear[startTime.getMonth()]}
      ${startTime.getDate()}`;
  }

  return date;
}

export function getEventDateStringAbbr(startTimeInput) {
  return getEventDateString(startTimeInput, true);
}

export function isToday(time) {
  const now = new Date();
  return (
    now.getFullYear() === time.getFullYear() &&
    now.getMonth() === time.getMonth() &&
    now.getDate() === time.getDate()
  );
}

export function isTomorrow(time) {
  const now = new Date();
  const tmr = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

  return (
    tmr.getFullYear() === time.getFullYear() &&
    tmr.getMonth() === time.getMonth() &&
    tmr.getDate() === time.getDate()
  );
}

/**
 * Sort events by start time
 * @param {Object[]} events array of events to sort
 * @returns {Object[]} sorted array of events
 */
export function sortEventByStartTime(events = []) {
  events.sort((event1, event2) => {
    return event1.time.start.seconds - event2.time.start.seconds;
  });
}

/**
 * A user slice, to be used for displaying user details outside of a profile.
 * @typedef {Object} UserSlice
 * @property {string} uid - Unique user ID.
 * @property {string} firstName - User first name.
 * @property {string} lastName - User last name.
 * @property {number} classYear - User class year.
 * @property {string} [photoUrl=""] - Permanent avatar image url
 */

// TODO(CP): Should use middleware instead of passing state here.
/**
 * Retrieves user slices for an array of uids.
 * @param {string[]} uids Array of userIds to retrieve
 * @param {Object} allUsers meta users document object
 * @returns {UserSlice[]} Array of user slices
 */
export const generateUserSlices = (uids = [], allUsers = {}) => {
  const slices = [];
  for (let uid of uids) {
    if (allUsers[uid]) slices.push({ uid, ...allUsers[uid] });
  }
  return slices;
};

/**
 * Retrieves user slices for an array of uids.
 * @param {string} uids uid to retrieve
 * @param {Object} allUsers meta users document object
 * @returns {UserSlice} User slice
 */
export const generateOneUserSlice = (uid = '', allUsers = {}) =>
  allUsers[uid] ? { uid, ...allUsers[uid] } : null;

export const stringHashCode = (s) => {
  for (var i = 0, h = 0; i < s.length; i++)
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return h;
};

export const generateAvatarColor = (uid) => {
  const random = pseudoRandomGenerator(stringHashCode(uid));
  const idx = Math.floor((random() * 100) % BACKGROUND_COLORS.length);
  return BACKGROUND_COLORS[idx];
};
