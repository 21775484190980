import React from 'react';
import { makeEventDetailsHeader as useStyles } from './styles';
import RibbonArray from '../shared/RibbonArray';
import ClampLines from 'react-clamp-lines';

const Header = (props) => {
  const { event } = props;
  const classes = useStyles();
  let ribbonArray = [event?.location?.type];
  if (event?.visibility === 'organization') ribbonArray.push(event.visibility);
  return (
    <div
      className={classes.root}
      style={{ backgroundImage: `url(${props.src})` }}
    >
      <div className={classes.vignette}>
        <div className={classes.eventTitle}>
          <RibbonArray ribbonArray={ribbonArray} />
          <ClampLines
            text={event?.title ?? 'Loading...'}
            lines={4}
            innerElement="h1"
            buttons={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
