import React from 'react';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import UserAvatar from '../SocialComponents/UserAvatar';
import UploadPhotoButton from './UploadPhotoButton';
import RemovePhotoButton from './RemovePhotoButton';
import { Chip } from '@material-ui/core';

export const StyledAvatarWithClass = withTheme(styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 128px;
  margin-right: 24px;

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  .MuiAvatar-root {
    width: 128px;
    height: 128px;
    overflow: hidden;
    position: relative;
  }

  #chip {
    position: absolute;
    background-color: ${(props) => props.theme.palette.primary.main};
    color: #ffffff;
    width: 96px;
    height: 36px;
    top: 111px;
    border-radius: 4px;
  }
`);

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '504px',
    alignItems: 'center',
    margin: '48px auto 48px',
    '& h1': {
      margin: '0px 0px 8px',
      fontSize: '32px',
      lineHeight: '36px',
      fontWeight: '700',
    },
  },
  buttonWrapper: {
    display: 'flex',
  },
});

const getSliceFromProfile = (profile) => {
  const { firstName = '', lastName = '', uid = '' } = profile;
  const { classYear, photoUrl = '' } = profile;
  return { uid, firstName, lastName, classYear, photoUrl };
};

const EditProfilePicture = (props) => {
  const { profile } = props;
  const classes = useStyles();
  const slice = getSliceFromProfile(profile);
  const badgeContent = slice.classYear ? (
    <p>{`Class of '${slice.classYear.toString().slice(2)}`}</p>
  ) : null;

  return (
    <div className={classes.root}>
      <StyledAvatarWithClass>
        <UserAvatar slice={slice} size="large" />
        <Chip id="chip" label={badgeContent} />
      </StyledAvatarWithClass>
      <div>
        <h1 style={{ textAlign: 'left' }}>
          {slice.firstName} {slice.lastName}
        </h1>
        <div className={classes.buttonWrapper}>
          <UploadPhotoButton uid={profile.uid} />
          <span style={{ whiteSpace: 'pre-wrap' }}>{' / '}</span>
          <RemovePhotoButton uid={profile.uid} />
        </div>
      </div>
    </div>
  );
};

export default EditProfilePicture;
