import React from 'react';
import { useFirebaseConnect, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, connect } from 'react-redux';
import { compose } from 'redux';
import { makeStyles } from '@material-ui/core';
import TopNavBar from '../TopNavBar';
import OrganizationCarousel from '../shared/OrganizationCarousel';
import TagBrowse from '../shared/TagBrowse';
import { BigHeading, Footer } from '../shared/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  section: {
    textAlign: 'left',
    paddingLeft: '200px',
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
}));

const MyInterests = () => {
  const classes = useStyles();
  useFirestoreConnect({ collection: 'meta', doc: 'organizations' });
  const organizationSlicesMap =
    useSelector((state) => state.firestore.data?.meta?.organizations) || {};

  useFirebaseConnect([]);
  const profile = useSelector((state) => state.firebase?.profile);
  const myOrgIds = profile?.orgs ?? [];
  const interests = profile?.interests ?? [];

  const myOrgs = myOrgIds.map((orgId) => ({
    orgId,
    ...organizationSlicesMap[orgId],
  }));
  const studentOrgs = myOrgs.filter((org) => !org.department);
  const academicOrgs = myOrgs.filter((org) => org.department);

  return (
    <div>
      <BigHeading>My Interests</BigHeading>

      {academicOrgs.length > 0 ? (
        <OrganizationCarousel
          orgs={academicOrgs}
          title="Academic Orgs"
          linkText="Edit"
          linkUrl="/profile/edit/academic-orgs"
        />
      ) : (
        <div className={classes.section}>
          <h2>Academic Orgs</h2>
          <p>
            <Link to="/profile/edit/academic-orgs">Select academic orgs</Link>{' '}
            that you are interested in.
          </p>
        </div>
      )}

      {studentOrgs.length > 0 ? (
        <OrganizationCarousel
          orgs={studentOrgs}
          title="Student Orgs"
          linkText="Edit"
          linkUrl="/profile/edit/student-orgs"
        />
      ) : (
        <div className={classes.section}>
          <h2>Student Orgs</h2>
          <p>
            <Link to="/profile/edit/student-orgs">Select student orgs</Link>{' '}
            that you are interested in.
          </p>
        </div>
      )}

      {interests.length > 0 ? (
        <TagBrowse tags={interests} title="Tags" editLink linkText="Edit" />
      ) : null}

      <Footer />
    </div>
  );
};

const mapStateToProps = ({ firebase }) => {
  return {
    uid: firebase.auth?.uid,
    myOrgIds: firebase.profile?.orgs,
    interests: firebase.profile?.interests,
  };
};

export default compose(connect(mapStateToProps))(MyInterests);
