import React from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
} from '@material-ui/core';
import UserAvatar from './UserAvatar';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { makeUserCardStyles as useStyles } from './styles';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import { generateUserSlices } from '../utils';

const UserCard = (props) => {
  const { uid, isRequest, disableLink } = props;

  useFirestoreConnect(['meta']);
  const allUsers = useSelector((state) => state.firestore?.data?.meta?.users);
  const slice = generateUserSlices([uid], allUsers)[0] ?? {};
  const { firstName, lastName, classYear } = slice;

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea disabled={disableLink}>
        <UserAvatar slice={slice} />
        <CardContent className={classes.content}>
          <h4>{`${firstName} ${lastName}`}</h4>
          {classYear ? (
            <p>{`Class of '${classYear.toString().slice(-2)}`}</p>
          ) : null}
        </CardContent>
      </CardActionArea>
      {isRequest ? (
        <CardActions>
          <IconButton>
            <ClearIcon />
          </IconButton>
          <IconButton>
            <CheckIcon />
          </IconButton>
        </CardActions>
      ) : null}
    </Card>
  );
};

UserCard.propTypes = {
  uid: PropTypes.string.isRequired,
  isRequest: PropTypes.bool,
  disableLink: PropTypes.bool,
};

export default UserCard;
