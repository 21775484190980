import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import TagChip from './TagChip';
import { SectionTitle } from './styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
  },
  content: {
    width: '1044px',
    paddingLeft: '200px',
  },
  editButton: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
}));

const TagBrowse = (props) => {
  const { tags, title, editLink, linkText } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SectionTitle marginRight="205px">
        <h2>{title}</h2>
        {editLink ? (
          <Link to="/profile/edit/tags" className={classes.editButton}>
            {linkText ?? 'More'}
          </Link>
        ) : null}
      </SectionTitle>
      <div className={classes.content}>
        {tags.map((item) => (
          <Link
            to={`/search/{"tags":["${item}"]}`}
            style={{ textDecoration: 'none' }}
            key={item}
          >
            <TagChip
              style={{
                fontSize: '14px',
                height: '36px',
              }}
              label={item}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

TagBrowse.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  editLink: PropTypes.bool,
  linkText: PropTypes.string,
};

export default TagBrowse;
