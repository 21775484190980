import React, { useState } from 'react';
import TopNavBar from '../TopNavBar';
import { useSelector } from 'react-redux';
import { useFirestore, useFirebaseConnect } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import BigPrimaryButton from '../shared/BigPrimaryButton';
import { useHistory } from 'react-router-dom';
import TagsPicker from '../Onboarding/TagsPicker';
import { BigHeading } from '../shared/styles';

const useStyles = makeStyles({
  tagsPicker: {
    padding: '16px 205px 24px 200px',
  },
  buttonWrapper: {
    width: '100%',
    bottom: '48px',
    pointerEvents: 'none',
    '& button': {
      pointerEvents: 'auto',
      height: '52px',
      marginBottom: '48px',
    },
  },
});

const EditInterests = () => {
  useFirebaseConnect([]);
  const profile = useSelector((state) => state.firebase?.profile);
  const history = useHistory();
  const [selectedTags, setSelectedTags] = useState({});
  const firestore = useFirestore();

  const [profileIsLoaded, setProfileIsLoaded] = useState(false);
  if (profile.isLoaded && !profileIsLoaded) {
    const profileTags = profile.interests ?? [];
    const tagsToUpdate = {};
    profileTags.forEach((attr) => (tagsToUpdate[attr] = true));
    setSelectedTags({
      ...selectedTags,
      ...tagsToUpdate,
    });
    setProfileIsLoaded(true);
  }

  const handleUpdateInterests = async (attrs) => {
    try {
      await firestore.update(
        { collection: 'users', doc: profile.uid },
        {
          interests: attrs,
        }
      );
      console.log('Interests added');
    } catch (error) {
      console.log('Error adding interests', error.toString());
    }
  };

  const updateProfile = () => {
    const attrs = [];
    for (let attr in selectedTags) {
      if (selectedTags[attr]) attrs.push(attr);
    }
    handleUpdateInterests(attrs).then(() => history.push('/my-interests'));
  };

  const classes = useStyles();
  return (
    <div>
      <BigHeading>Tags</BigHeading>
      <div className={classes.tagsPicker}>
        <TagsPicker
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </div>
      <div className={classes.buttonWrapper}>
        <BigPrimaryButton
          variant="contained"
          color="primary"
          onClick={updateProfile}
        >
          Finish!
        </BigPrimaryButton>
      </div>
    </div>
  );
};

export default EditInterests;
