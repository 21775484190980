import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import UserAvatar from './UserAvatar';
import { makeUserCardStyles as useStyles } from './styles';
import PropTypes from 'prop-types';

const UnconnectedUserCard = (props) => {
  const { fullName } = props;
  const [firstName, lastName] = fullName.split(' ');
  const fakeAvatarSlice = {
    firstName,
    lastName,
  };

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.cardNoActionArea}>
        <UserAvatar slice={fakeAvatarSlice} />
        <CardContent className={classes.content}>
          <h4>{fullName}</h4>
        </CardContent>
      </div>
    </Card>
  );
};

UnconnectedUserCard.propTypes = {
  fullName: PropTypes.string.isRequired,
};

export default UnconnectedUserCard;
