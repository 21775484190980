import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import EditProfileForm from '../Onboarding/EditProfileForm';
import BigPrimaryButton from '../shared/BigPrimaryButton';

const useStyles = makeStyles({
  saveChangeButton: {
    height: '52px',
    marginTop: '48px',
  },
});

const ErrorMessage = () => (
  <div>
    <p style={{ fontSize: '16px', color: 'red' }}>
      Sorry, you need to fill out all of these fields for your profile!
    </p>
  </div>
);

const EditProfile = (props) => {
  const { profile } = props;
  const firebase = useFirebase();
  const firestore = useFirestore();
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    classYear: '',
  });

  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [profileIsLoaded, setProfileIsLoaded] = useState(false);

  if (profile.isLoaded && !profileIsLoaded) {
    setFormState({
      ...formState,
      firstName: profile?.firstName ?? '',
      lastName: profile?.lastName ?? '',
      classYear: profile?.classYear ?? '',
    });
    setProfileIsLoaded(true);
  }

  const handleChange = (e, fieldName) => {
    let value = e.target.value;
    setFormState({
      ...formState,
      [fieldName]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, classYear } = formState;
    if (!firstName || !lastName || !classYear) {
      setDisplayErrorMessage(true);
      return;
    }
    const user = firebase.auth().currentUser;
    user.updateProfile({ displayName: firstName });
    firestore
      .collection('users')
      .doc(user.uid)
      .update({
        uid: user.uid,
        firstName,
        lastName,
        classYear,
      })
      .then(() => {
        console.log('Successfully updated!');
        window.alert('Successfully updated!');
      })
      .catch((error) =>
        console.error(`ERROR: Account Update\n ${error.message}`)
      );
    console.log(formState);
  };

  const classes = useStyles();

  return (
    <div>
      <EditProfileForm handleChange={handleChange} formState={formState} />
      {displayErrorMessage ? <ErrorMessage /> : null}
      <BigPrimaryButton
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className={classes.saveChangeButton}
      >
        Save Changes
      </BigPrimaryButton>
    </div>
  );
};

export default EditProfile;
