import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import { makeStyles } from '@material-ui/core';
import { BigHeading, SectionTitle, Footer } from '../shared/styles';
import { useFirebaseConnect, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { sortEventByStartTime } from '../utils';
import { Link } from 'react-router-dom';
import TopNavBar from '../TopNavBar';
import CardCarousel from '../CardCarousel';

const useStyles = makeStyles((theme) => ({
  empty: {
    paddingLeft: '174px',
    '& p': {
      textAlign: 'left',
      margin: theme.spacing(3),
      marginLeft: '0px',
    },
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
}));

const MyEvents = () => {
  const classes = useStyles();
  useFirebaseConnect([]);
  const profile = useSelector((state) => state.firebase?.profile);

  useFirestoreConnect(['events']);
  const eventsMap = useSelector((state) => state.firestore?.data?.events);
  const allEvents = [];
  if (eventsMap) {
    Object.keys(eventsMap).forEach((eventId) =>
      allEvents.push(eventsMap[eventId])
    );
  }

  const goingEventIds = profile?.events?.going ?? [];
  const interestedEventIds = profile?.events?.interested ?? [];
  const goingEvents = [];
  const interestedEvents = [];
  const upcomingEvents = [];

  const [upcomingEventIds, setUpcomingEventIds] = useState([]);
  useEffect(() => {
    if (!profile?.uid) return;
    const getUserUpcomingEvents = async () => {
      const upcomingEvents = firebase
        .functions()
        .httpsCallable('getUserUpcomingEvents');
      try {
        const upcoming = await upcomingEvents({ uid: profile.uid });
        setUpcomingEventIds(upcoming?.data ?? []);
      } catch (err) {
        console.error(err);
      }
    };
    getUserUpcomingEvents();
  });

  for (const event of allEvents) {
    if (goingEventIds.includes(event.eventId)) goingEvents.push(event);
    else if (interestedEventIds.includes(event.eventId))
      interestedEvents.push(event);

    if (upcomingEventIds.includes(event.eventId)) upcomingEvents.push(event);
  }
  sortEventByStartTime(goingEvents);
  sortEventByStartTime(interestedEvents);
  sortEventByStartTime(upcomingEvents);

  const EmptyDiv = () => (
    <div className={classes.empty}>
      <p>
        It's a bit empty here! Check out <Link to="/">events for you</Link> or{' '}
        <Link to="/events">browse events</Link>.
      </p>
    </div>
  );

  return (
    <div>
      <BigHeading>My Events</BigHeading>

      <section>
        <SectionTitle>
          <h2>Upcoming This Week</h2>
        </SectionTitle>
        {upcomingEvents.length > 0 ? (
          <CardCarousel events={upcomingEvents} />
        ) : (
          <EmptyDiv />
        )}
      </section>

      <section>
        <SectionTitle>
          <h2>Going</h2>
        </SectionTitle>
        {goingEvents.length > 0 ? (
          <CardCarousel events={goingEvents} />
        ) : (
          <EmptyDiv />
        )}
      </section>

      <section>
        <SectionTitle>
          <h2>Interested</h2>
        </SectionTitle>
        {interestedEvents.length > 0 ? (
          <CardCarousel events={interestedEvents} />
        ) : (
          <EmptyDiv />
        )}
      </section>

      <Footer />
    </div>
  );
};

export default MyEvents;
