import React, { useState } from 'react';
import 'date-fns';
import { TextField, Button, Grid, Tabs, Tab } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TwineWordmark from '../../assets/twine-wordmark-400w.png';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Tags from './Tags.js';
import 'firebase/functions';
import {
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  useFirebase,
  useFirebaseConnect,
  useFirestoreConnect,
  useFirestore,
} from 'react-redux-firebase';
import { getLinkType } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    padding: '32px',
    maxWidth: '500px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '5px',
    borderColor: '#bababa',
  },
  header: {
    marginBottom: '24px',
    '& p, h1': {
      margin: theme.spacing(1),
    },
    '& img': {
      width: '100px',
      display: 'block',
      margin: theme.spacing(1),
      marginTop: '16px',
      marginBottom: '16px',
    },
  },
  form: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      margin: theme.spacing(1),
      width: '100%',
    },

    '& .MuiInput-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '& .MuiFormLabel-root': {
      margin: theme.spacing(1),
      width: '100%',
    },

    '& .MuiMenuItem-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '& .MuiInputLabel-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '& .MuiTabs-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '& .MuiButton-root': {
      margin: theme.spacing(1),
      fontSize: '16px',
      textTransform: 'none',
    },
    '& img': {
      margin: theme.spacing(1),
      width: '300px',
    },
  },
}));

const EventSubmission = () => {
  useFirebaseConnect([]);
  const { uid, email, displayName } = useSelector(
    (state) => state.firebase.auth
  );
  const firebase = useFirebase();
  const db = useFirestore();
  const classes = useStyles();
  useFirestoreConnect([{ collection: 'organizations' }]);
  useFirestoreConnect([{ collection: 'meta', doc: 'tags' }]);
  const orgList = useSelector((state) => state.firestore.ordered.organizations);
  const meta = useSelector((state) => state.firestore.data.meta);
  const tagList = meta?.tags.all;

  let now = new Date();
  const [suggestions, setSuggestions] = useState([]);
  const [rendering, setRendering] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [displayPasscodeBox, setDisplayPasscodeBox] = useState(false);
  const [img, setImg] = useState();
  const [values, setValues] = useState({
    startTime: now,
    endTime: new Date(now.getTime() + 30 * 60000),
    tags: [],
    status: 'pending',
    linkType: null,
    'location-name': '',
    'location-address': '',
    'location-url': '',
    'location-passcode': '',
  });

  const handleStartTimeChange = (date) => {
    let newEndTime = new Date(date.getTime());
    newEndTime.setHours(values.endTime.getHours());
    newEndTime.setMinutes(values.endTime.getMinutes());
    newEndTime =
      newEndTime > date ? newEndTime : new Date(date.getTime() + 30 * 60000);
    setValues({ ...values, startTime: date, endTime: newEndTime });
  };

  let menuItems = [''];
  if (orgList) {
    menuItems = orgList.concat({ name: '' });
  }

  const handleEndTimeChange = (time) => {
    time =
      time > values.startTime
        ? time
        : new Date(values.startTime.getTime() + 60000);
    setValues({ ...values, endTime: time });
  };

  const handleImageChange = async (e) => {
    const img = e.target.files[0];
    if (img) setImg(img);
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'location-url') {
      const linkType = getLinkType(value);
      setValues({ ...values, [name]: value, linkType });
    } else {
      setValues({ ...values, [name]: value });
    }
  };
  const handleInputDescription = async (event) => {
    if (values.description) {
      const getTopicsFromText = firebase
        .functions()
        .httpsCallable('getTopicsFromText');
      var titleString = String(values.title);
      var descriptionString = String(values.description);
      try {
        setRendering(true);
        const tagsFromTextObject = await getTopicsFromText({
          title: titleString,
          description: descriptionString,
        });
        const sortedSuggestions = Object.entries(tagsFromTextObject.data)
          .sort(([, a], [, b]) => b - a)
          .map((arr) => arr[0]);

        setSuggestions(sortedSuggestions.slice(0, 10));
        setRendering(false);
      } catch (e) {
        try {
          console.log('Retrying getting suggested tags');
          const tagsFromTextObject = await getTopicsFromText({
            title: titleString,
            description: descriptionString,
          });
          const sortedSuggestions = Object.entries(tagsFromTextObject.data)
            .sort(([, a], [, b]) => b - a)
            .map((arr) => arr[0]);
          setSuggestions(sortedSuggestions.slice(0, 10));
          setRendering(false);
        } catch (e) {
          console.log('Error getting suggested tags');
          setRendering(false);
        }
      }
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!values.title) {
      alert('Your event needs a title');
    } else if (!values.description) {
      alert('Your event needs a description!');
    } else if (!values.host || values.host === ' ') {
      alert('Your event needs a host!');
    } else if (values.tags.length < 1) {
      alert('Your event needs atleast 1 Tag!');
    } else if (!values['location-address'] && !values['location-url']) {
      alert('Your event needs a Physical Adress or Event Link!');
    } else {
      const eventDoc = {
        ...values,
        'location-type': activeTab === 0 ? 'web' : 'place',
        createdAt: new Date(),
        submittedBy: { uid, email, displayName },
      };
      try {
        const docRef = await db.collection('pendingEvents').add(eventDoc);
        const storageRef = firebase.storage().ref();
        const imgRef = storageRef.child(`events/${docRef.id}`);
        let imageUrl;
        if (img) {
          await imgRef.put(img);
          imageUrl = await imgRef.getDownloadURL();
        } else imageUrl = null;

        await db.collection('pendingEvents').doc(docRef.id).update({
          imageUrl: imageUrl,
        });
        setSubmitted(true);
      } catch (err) {
        console.error(err);
      }
    }
  };
  if (submitted) {
    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <img
            src={TwineWordmark}
            alt="twine"
            className={classes.twineWordmark}
          />
        </div>
        <h4>
          Thank you for submitting your event details! Your event will be
          available on Twine shortly.
        </h4>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <img
          src={TwineWordmark}
          alt="twine"
          className={classes.twineWordmark}
        />
        <h1>Event Submission Form</h1>
        <p>
          Events submitted through this form will only be visible to users
          logged into Twine with an Oberlin College email address. That means
          you can submit things that are just for the inner community!
          <br />
          <br />
          To submit a public event, you may visit the{' '}
          <a href="https://calendar.oberlin.edu/">Oberlin Calendar</a>.
        </p>
      </div>
      <form className={classes.form} autoComplete="off">
        <TextField
          required
          variant="outlined"
          label="Event Title"
          name="title"
          onChange={handleInputChange}
        />
        <TextField
          required
          multiline
          variant="outlined"
          label="Event Description"
          name="description"
          rows={6}
          onBlur={handleInputDescription}
          onChange={handleInputChange}
        />
        <Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              style={{ width: '60%', minWidth: '200px' }}
              label="Start Time"
              inputVariant="outlined"
              value={values.startTime}
              onChange={handleStartTimeChange}
            />
            <TimePicker
              style={{ width: '30%', minWidth: '100px' }}
              label="End Time"
              inputVariant="outlined"
              value={values.endTime}
              onChange={handleEndTimeChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <div className="orgselect" style={{ width: '100%', margin: '2%' }}>
          <InputLabel required id="host">
            Host Organization or Department
          </InputLabel>
          <Select
            required
            name="host"
            labelId="host"
            id="host"
            value={values.host ? values.host : ''}
            onChange={handleInputChange}
          >
            {menuItems
              .map((org) => {
                return (
                  <MenuItem required key={org.name} value={org.name}>
                    {' '}
                    {org.name}{' '}
                  </MenuItem>
                );
              })
              .sort((a, b) => (a.props.value > b.props.value ? 1 : -1))}
          </Select>
        </div>
        <Button component="label" color="primary">
          Upload Event Image
          <input
            type="file"
            hidden
            onChange={handleImageChange}
            accept="image/*"
          />
        </Button>
        {img ? <img src={URL.createObjectURL(img)} alt="Event Cover" /> : null}
        <Tags
          rendering={rendering}
          metaTags={tagList}
          suggestions={suggestions}
          setTags={(tags) => setValues({ ...values, tags })}
        />
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          onChange={(event, newValue) => setActiveTab(newValue)}
          aria-label="event type tabs"
        >
          <Tab label="Virtual" />
          <Tab label="In Person" />
        </Tabs>
        {activeTab === 0 ? (
          <React.Fragment>
            <TextField
              variant="outlined"
              label="Event URL"
              name="location-url"
              required
              value={values['location-url']}
              placeholder="https://oberlin.zoom.us/j/12345678990"
              onChange={handleInputChange}
            />
            <p
              style={{
                cursor: 'pointer',
                color: 'gray',
                fontStyle: 'italic',
                textDecorationLine: 'underline',
                margin: '0px 8px 8px 8px',
                display: 'inline-block',
              }}
              onClick={() => setDisplayPasscodeBox(!displayPasscodeBox)}
            >
              Have a passcode for your link?
            </p>
            {displayPasscodeBox ? (
              <TextField
                variant="outlined"
                label="Optional Passcode"
                name="location-passcode"
                value={values['location-passcode']}
                onChange={handleInputChange}
              />
            ) : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TextField
              variant="outlined"
              label="Location Name"
              name="location-name"
              placeholder="Finney Chapel"
              value={values['location-name']}
              onChange={handleInputChange}
            />
            <TextField
              required
              variant="outlined"
              label="Address"
              name="location-address"
              value={values['location-address']}
              placeholder="90 N Professor St, Oberlin, OH 44074"
              onChange={handleInputChange}
            />
          </React.Fragment>
        )}
        <TextField
          multiline
          rows={3}
          variant="outlined"
          label="Other Information"
          name="other"
          onChange={handleInputChange}
        />
        <Button
          onClick={(event) => {
            handleFormSubmit(event);
          }}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default EventSubmission;
