import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TwineWordmark from '../../assets/twine-wordmark-400w.png';
import jwt_decode from 'jwt-decode';
import { getFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'absolute',
    top: '35%',
    width: '100%',
    '& img': {
      display: 'block',
      width: '200px',
      margin: 'auto',
      marginBottom: '36px',
    },
    '& p': {
      margin: '15px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}));

const validateToken = (idToken) => {
  const token = jwt_decode(idToken);
  return token.hd === 'oberlin.edu';
};

const googleSignIn = () => {
  let firebase = getFirebase();
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ hd: 'oberlin.edu' });
  firebase.auth().signInWithRedirect(provider);
};

const handleRedirectFromSignin = async (history) => {
  let firebase = getFirebase();
  const result = await firebase.auth().getRedirectResult();
  if (!result.user) return;
  else {
    if (!validateToken(result.credential.idToken)) {
      console.error(
        'Error: Invalid host domain. Please log in using your oberlin.edu account.'
      );
      firebase
        .auth()
        .signOut()
        .then(() => history.push('/'));
    } else {
      history.push('/');
    }
  }
};

const Login = (props) => {
  const history = useHistory();
  const classes = useStyles();
  handleRedirectFromSignin(history);
  if (props.isLoggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <div className={classes.content}>
      <img src={TwineWordmark} alt="twine" />
      <Button variant="contained" onClick={googleSignIn} color="secondary">
        Sign In with Google
      </Button>
      <p>Twine works with your existing Oberlin account.</p>
    </div>
  );
};

export default connect(({ firebase }) => ({
  isLoggedIn: firebase.auth.uid ? true : false,
}))(Login);
