import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as VirtualIcon } from './images/computer.svg';
import { ReactComponent as InPersonIcon } from './images/inPerson.svg';
import { ReactComponent as MembersIcon } from './images/members.svg';

const useStyles = makeStyles({
  ribbonContainer: {
    display: 'flex',
  },
  ribbonLarge: {
    position: 'relative',
    flex: 1,
    //marginLeft: "16px",
    borderRadius: '0px 0px 2px 2px',
    height: '40px',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '-0.25px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-around',
    padding: '8px',
    boxSizing: 'border-box',
    '& svg': {
      height: '16px',
      width: '16px',
      position: 'absolute',
      left: '8px',
      bottom: '8px',
      stroke: 'white',
    },
    '& span': {
      position: 'absolute',
      bottom: '8px',
      right: '8px',
    },
  },
});

const RibbonArray = (props) => {
  const { ribbonArray } = props;
  const classes = useStyles();
  let typeLabel, background, maxWidth;

  const RibbonIcon = (props) => {
    const { type } = props;
    switch (type) {
      case 'web':
        return <VirtualIcon />;
      case 'place':
        return <InPersonIcon />;
      case 'organization':
        return <MembersIcon />;
      default:
    }
    return null;
  };

  const setIcon = (icon, idx) => {
    switch (icon) {
      case 'web':
        typeLabel = 'Virtual';
        background = '#2f80ed';
        maxWidth = '75px';
        break;
      case 'place':
        typeLabel = 'In-Person';
        background = '#f2c94c';
        maxWidth = '93px';
        break;
      case 'organization':
        typeLabel = 'Members-only';
        background = '#FF521E';
        maxWidth = '120px';
        break;
      default:
    }
    return (
      <div
        className={classes.ribbonLarge}
        style={{ background: background, maxWidth: maxWidth }}
        key={`${icon}-${idx}`}
      >
        <RibbonIcon type={icon} />
        <span>{typeLabel}</span>
      </div>
    );
  };

  return (
    <div className={classes.ribbonContainer}>
      {ribbonArray.map((icon, idx) => setIcon(icon, idx))}
    </div>
  );
};

export default RibbonArray;
