import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledBackgroundDiv = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src}),
    linear-gradient(45deg, #ffce00 10.02%, #ff521e 50.02%, #c7003a 89.98%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
`;

const useStylesCard = makeStyles((theme) => ({
  card: {
    height: (props) =>
      props.large ? '160px' : props.orangeHighlight ? '94px' : '88px',
    width: (props) =>
      props.large ? '312px' : props.orangeHighlight ? '162px' : '156px',
    border: (props) =>
      props.large || props.orangeHighlight ? '4px transparent' : '4px solid',
    display: 'flex',
    flexFlow: 'column nowrap',
    position: 'relative',
    borderRadius: '4px',
    borderColor: (props) =>
      props.blueHighlight ? theme.palette.secondary.main : 'transparent',
    boxShadow: (props) => {
      if (props.large) return 'rgb(100 100 100) 0px 0px 6px 5px';
      else if (props.orangeHighlight) return '#ff521e 0px 0px 6px 5px';
      else return 'transparent';
    },
  },
  vignette: {
    width: '100%',
    height: '100%',
    borderRadius: '4px',
    background: `linear-gradient(180deg, rgba(51, 51, 51, 0.25) 0%, rgba(51, 51, 51, 0) 100%),
    linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0) 0.01%, rgba(51, 51, 51, 0.25) 100%)`,
  },
  name: {
    color: 'white',
    margin: '8px',
    position: 'absolute',
    bottom: '0',
    left: '0',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
  },
}));

const OrganizationCard = (props) => {
  const { orgId } = props;
  let { onClick } = props;
  const history = useHistory();
  if (!onClick) onClick = () => history.push(`/organizations/${orgId}`);

  useFirestoreConnect(['meta']);
  const org = useSelector(
    (state) => state.firestore?.data?.meta?.organizations[orgId]
  );
  const src = org?.imageUrl;

  const classes = useStylesCard(props);

  return (
    <div className={classes.card} onClick={onClick}>
      <StyledBackgroundDiv src={src}>
        <div className={classes.vignette}></div>
      </StyledBackgroundDiv>
      <h3 className={classes.name}>{org?.name}</h3>
    </div>
  );
};

OrganizationCard.propTypes = {
  orgId: PropTypes.string.isRequired,
  blueHighlight: PropTypes.bool,
  onClick: PropTypes.func,
  large: PropTypes.bool,
  orangeHighlight: PropTypes.bool,
};

export default OrganizationCard;
