import React from 'react';
import TopNavBar from '../TopNavBar';
import { BigHeading } from '../shared/styles';
import OrgsPickerContainer from '../Onboarding/OrgsPickerContainer';

const EditStudentOrgs = () => {
  return (
    <div>
      <BigHeading>Student Orgs</BigHeading>
      <OrgsPickerContainer isDepartment={false} rerouteOnDone="/my-interests" />
    </div>
  );
};

export default EditStudentOrgs;
