// Engagement types for engageEvent action
export const engagements = {
  GOING: 'going',
  INTERESTED: 'interested',
  UNINTERESTED: 'uninterested',
};

// Rating types for rateTags action
export const tagRatings = {
  LIKE: 'like',
  DISLIKE: 'dislike',
  UNLIKE: 'unlike',
  UNDISLIKE: 'undislike',
};

export const filterTypes = {
  MOST_POPULAR: 'mostPopular',
  DATE: 'date',
  LOCATION_TYPE: 'locationType',
  PRICE: 'price',
  TAGS: 'tags',
  SOCIAL: 'social',
  MY_ORGS: 'myOrgs',
};

export const MAX_PHOTO_SIZE = 5;
export const MB_TO_BYTES = 1024 * 1024;
