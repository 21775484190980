import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import {
  makeEventDetailsPage as useStyles,
  JustifyContentCenterDiv,
} from './styles';
import PlaceholderImage from '../../assets/placeholder.jpg';
import TopNavBar from '../TopNavBar';
import Header from './Header';
import PeekRespondents from '../shared/PeekRespondents';
import JoinButtons from './JoinButtons';
import ReactHtmlParser from 'react-html-parser';
import TagChip from '../shared/TagChip';
import OrganizationCard from '../shared/OrganizationCard';
import EngagementButtonContainer from '../shared/EngagementButtonContainer';
import ShareButton from '../shared/ShareButton';

const parseDescription = (description) => {
  return ReactHtmlParser(description);
};

const EventDetails = (props) => {
  const { isLoggedIn } = props;
  const { id: eventId } = useParams();
  const [engagementBar, setEngagementBar] = useState(false);
  const classes = useStyles();

  useFirestoreConnect('events');
  const events = useSelector((state) => state.firestore?.data?.events);
  const event = events?.[eventId];

  const imageUrl = event?.imageUrl || PlaceholderImage;
  const zoomLink = event?.url;
  const type = event?.location?.type ?? 'unknown';
  const locationName = event?.location?.name;
  const address = event?.location?.address;
  const tags = event?.filters?.attributes ?? [];
  const title = event?.title;
  const eventURL = `${window.location.origin}/events/${eventId}`;

  const owner = event?.owner ?? 'unknown';
  const ownerExists = owner?.id !== undefined;
  const ownerName = owner?.name;

  const changeEngagementBar = () => {
    if (window.scrollY > 90) {
      setEngagementBar(true);
    } else {
      setEngagementBar(false);
    }
  };
  window.addEventListener('scroll', changeEngagementBar);

  return (
    <div className={classes.root}>
      <div
        className={
          engagementBar ? classes.engagementActive : classes.engagement
        }
      >
        <EngagementButtonContainer
          event={event}
          locationType={type}
          cardType="EventDetails"
        />
        <ShareButton title={title} url={eventURL} type="eventDetails" />
      </div>

      <JustifyContentCenterDiv>
        <Header src={imageUrl} event={event} type={type} />
      </JustifyContentCenterDiv>

      <JustifyContentCenterDiv>
        <div className={classes.contentBox}>
          <div className={classes.content}>
            <PeekRespondents eventId={eventId} isEventDetails />

            {type !== 'place' ? (
              <div>
                <h3>Join Event</h3>
                <JoinButtons joinLink={zoomLink} isNonUser={!isLoggedIn} />
              </div>
            ) : null}

            <div>
              <h3>Event Details</h3>
              {parseDescription(event?.description ?? 'Loading..')}
            </div>

            {type === 'place' ? (
              <div>
                <h3>Directions</h3>
                <p>{locationName ? locationName : address}</p>
              </div>
            ) : (
              <div>
                <h3>Directions</h3>
                <p>Online</p>
              </div>
            )}

            {tags ? (
              <div>
                <h3>Event Tags</h3>
                {tags.map((tag) => (
                  <TagChip
                    style={{
                      fontSize: '14px',
                      height: '36px',
                    }}
                    key={tag}
                    label={tag}
                  />
                ))}
              </div>
            ) : null}

            {ownerExists ? (
              <div style={{ marginBottom: '48px' }}>
                <h3>Organizer</h3>
                <OrganizationCard orgId={owner?.id} />
              </div>
            ) : ownerName ? (
              <div>
                <h3>Organizer</h3>
                {owner?.name}
              </div>
            ) : null}
          </div>
        </div>
      </JustifyContentCenterDiv>

      <div className={classes.footer} />
    </div>
  );
};

export default EventDetails;
