import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const AuthRoute = (props) => {
  const { isLoggedIn } = props;
  const { children, component, redirectBase = '/login', ...rest } = props;

  if (component && isLoggedIn) {
    return <Route component={component} {...rest} />;
  }
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={`${redirectBase}?redirect=${location.pathname}${location.search}`}
          />
        );
      }}
    />
  );
};

export default AuthRoute;
