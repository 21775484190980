import { useSelector } from 'react-redux';
import React from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import { Button, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useFirestore } from 'react-redux-firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    padding: '32px',
    maxWidth: '500px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '5px',
    borderColor: '#bababa',
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

const FeaturedOrganization = () => {
  const classes = useStyles();
  const db = useFirestore();
  let orgsList = [];
  let currentlyFeaturedOrg = '';
  useFirestoreConnect([
    { collection: 'meta', doc: 'organizations' },
    { collection: 'meta', doc: 'appState' },
  ]);
  let allOrgs = useSelector(
    (state) => state.firestore.data?.meta?.organizations
  );
  let featuredOrg = useSelector(
    (state) => state.firestore.data?.meta?.appState?.featuredOrg
  );
  let featuredOrgDescription = useSelector(
    (state) => state.firestore.data?.meta?.appState?.featuredOrgDescription
  );

  if (!allOrgs) return null;

  const sorted = Object.entries(allOrgs).sort(function (a, b) {
    const nameA = a[1].name.toUpperCase();
    const nameB = b[1].name.toUpperCase();
    if (nameA > nameB) {
      return 1;
    } else {
      return -1;
    }
  });

  const handleInputChange = (e) => {
    featuredOrg = e.target.value;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    featuredOrgDescription = document.getElementById('description').value;
    try {
      await db.collection('meta').doc('appState').update({
        featuredOrg: featuredOrg,
        featuredOrgDescription: featuredOrgDescription,
      });
      console.log('Updated featured organization:', featuredOrg);
    } catch (err) {
      console.error(err);
    }
  };

  for (let value of Object.values(sorted)) {
    if (value[0] === featuredOrg) {
      currentlyFeaturedOrg = value[1].name;
    }
    orgsList.push(
      <FormControlLabel
        value={value[0]}
        control={<Radio />}
        key={value[0]}
        label={value[1].name}
      />
    );
  }

  return (
    <div className={classes.root}>
      <h3>Currently Featured Org: {currentlyFeaturedOrg} </h3>
      <form className={classes.form} onSubmit={handleFormSubmit}>
        <RadioGroup name="org" onChange={handleInputChange}>
          <FormControlLabel
            value={''}
            control={<Radio />}
            key={'none'}
            label="No Featured Org"
          />
          {orgsList}
        </RadioGroup>
        <div>
          <h5>Optional Featured Organization Description</h5>
          <textarea
            rows="4"
            cols="50"
            placeholder="Optional description goes here"
            id="description"
          ></textarea>
          <br />
        </div>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default FeaturedOrganization;
