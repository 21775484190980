import React from 'react';
import { makeStyles } from '@material-ui/core';
import OrganizationCard from '../shared/OrganizationCard';
import LazyLoad from 'react-lazyload';

const useStyles = makeStyles({
  root: {
    padding: '24px 208px',
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'auto auto auto auto auto auto',
    gridRowGap: '24px',
    gridColumnGap: '17px',
    justifyContent: 'space-evenly',
    justifyItems: 'center',
    alignItems: 'center',
  },
});

const OrgsPicker = (props) => {
  const { orgs } = props;
  const { selectedOrgs, setSelectedOrgs } = props;

  const toggleOrg = (orgId) => {
    if (selectedOrgs[orgId])
      setSelectedOrgs({ ...selectedOrgs, [orgId]: false });
    else setSelectedOrgs({ ...selectedOrgs, [orgId]: true });
  };
  console.log(selectedOrgs);

  const classes = useStyles();
  if (!orgs) return <div>Loading...</div>;

  return (
    <div className={classes.root}>
      {orgs
        .sort((orgA, orgB) => orgA.name.localeCompare(orgB.name))
        .map((org) => (
          <LazyLoad height={50} offset={100} key={org.orgId} once>
            <OrganizationCard
              key={org.orgId}
              orgId={org.orgId}
              blueHighlight={selectedOrgs[org.orgId]}
              onClick={() => toggleOrg(org.orgId)}
            />
          </LazyLoad>
        ))}
    </div>
  );
};

export default OrgsPicker;
