import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import EventCard from './EventCard';
import arrow from './images/Vector.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '333px',
    width: '100%',
  },
  carousel: {
    display: 'flex',
    flexDirection: 'row',
    '& .carousel__back-button:disabled': {
      visibility: 'hidden',
    },
    '& .carousel__next-button:disabled': {
      visibility: 'hidden',
    },
  },
  container: {
    padding: '2px 5px',
    width: '1100px',
  },
  sliderWrap: {
    width: '1107px',
    marginLeft: '5px',
  },
  arrows: {
    position: 'relative',
    width: '24px',
    height: '24px',
    top: '165px',
    border: '0',
    fontSize: '20px',
    color: '#000000',
    backgroundColor: 'transparent',
  },
  rightArrow: {
    right: '20px',
  },
  leftArrow: {
    left: '-1px',
    transform: 'scaleX(-1)',
  },
}));

const CardCarousel = (props) => {
  const { events } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CarouselProvider
        naturalSlideWidth={200}
        naturalSlideHeight={200}
        totalSlides={events?.length}
        visibleSlides={3}
        step={3}
        className={classes.carousel}
      >
        <ButtonBack className={`${classes.arrows} ${classes.leftArrow}`}>
          <img src={arrow} alt="left-arrow" />
        </ButtonBack>
        <Slider
          className={classes.container}
          classNameTrayWrap={classes.sliderWrap}
        >
          {events?.map((item, idx) => (
            <Slide key={item.eventId} index={idx}>
              <EventCard
                key={idx}
                itemId={item.eventId}
                downloadUrl={item.imageUrl}
              />
            </Slide>
          )) ?? []}
        </Slider>
        <ButtonNext className={`${classes.arrows} ${classes.rightArrow}`}>
          <img src={arrow} alt="right-arrow" />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
};

export default CardCarousel;
