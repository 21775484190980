import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SectionTitle } from './styles';
import OrganizationCard from './OrganizationCard';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import arrow from '../CardCarousel/images/Vector.svg';
import { Link } from 'react-router-dom';

const useStylesCarousel = makeStyles((theme) => ({
  root: {
    height: '160px',
  },
  carousel: {
    display: 'flex',
    paddingLeft: '160px',
    '& .carousel__back-button:disabled': {
      visibility: 'hidden',
    },
    '& .carousel__next-button:disabled': {
      visibility: 'hidden',
    },
    '& .carousel__slide': {
      width: '156px',
      height: '88px',
    },
  },
  editButton: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
  container: {
    width: '1044px',
  },
  sliderWrap: {
    width: '1050px',
  },
  arrows: {
    position: 'relative',
    width: '24px',
    height: '24px',
    top: '30px',
    border: '0',
    fontSize: '20px',
    color: '#000000',
    backgroundColor: 'transparent',
  },
  leftArrow: {
    marginRight: '12px',
    transform: 'scaleX(-1)',
  },
}));

const OrganizationCarousel = (props) => {
  const { orgs, title } = props;
  const { linkText, linkUrl } = props;
  const classes = useStylesCarousel();

  return (
    <div className={classes.root}>
      <SectionTitle marginRight="205px">
        <h2>{title}</h2>
        {linkUrl ? (
          <Link to={linkUrl} className={classes.editButton}>
            {linkText ?? 'See More'}
          </Link>
        ) : null}
      </SectionTitle>
      <CarouselProvider
        naturalSlideWidth={200}
        naturalSlideHeight={200}
        totalSlides={orgs?.length}
        visibleSlides={6}
        step={3}
        className={classes.carousel}
      >
        <ButtonBack className={`${classes.arrows} ${classes.leftArrow}`}>
          <img src={arrow} alt="left-arrow" />
        </ButtonBack>
        <Slider
          className={classes.container}
          classNameTrayWrap={classes.sliderWrap}
        >
          {orgs?.map((item, idx) => (
            <Slide key={idx} index={idx}>
              <OrganizationCard key={idx} orgId={item.orgId} />
            </Slide>
          )) ?? []}
        </Slider>
        <ButtonNext className={classes.arrows}>
          <img src={arrow} alt="right-arrow" />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
};

export default OrganizationCarousel;
