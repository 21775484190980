import React from 'react';

const SocialMedia = (props) => {
  const { socialMedia } = props;
  if (!socialMedia) return null;
  const { facebook, instagram, website } = socialMedia;
  if (!facebook && !instagram && !website) return <p>None</p>;
  const fbRegex = /\.com\/\w+/i;
  const fbLabel = fbRegex.test(facebook)
    ? facebook?.match(fbRegex)[0].substr(5)
    : null;
  const httpsProtocolRegex = /https?:\/\//;
  const fbUrl = httpsProtocolRegex.test(facebook)
    ? facebook
    : `https://${facebook}`;

  const igLabel = instagram;
  const igUrl = `https://instagram.com/${igLabel.substr(1)}/`;

  const webLabel = website;
  const webUrl = httpsProtocolRegex.test(website)
    ? website
    : `https://www.${webLabel}`;
  return (
    <p>
      {fbLabel ? (
        <React.Fragment>
          Facebook:{' '}
          <a href={fbUrl} target="_blank" rel="noopener noreferrer">
            {fbLabel}
          </a>
          <br />
        </React.Fragment>
      ) : null}
      {igLabel ? (
        <React.Fragment>
          Instagram:{' '}
          <a href={igUrl} target="_blank" rel="noopener noreferrer">
            {igLabel}
          </a>
          <br />
        </React.Fragment>
      ) : null}

      {webLabel ? (
        <React.Fragment>
          Website:{' '}
          <a href={webUrl} target="_blank" rel="noopener noreferrer">
            {webLabel}
          </a>
          <br />
        </React.Fragment>
      ) : null}
    </p>
  );
};

export default SocialMedia;
