import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '472px',
    height: '120px',
    borderRadius: '5px',
  },
}));

const MapWithLocation = (props) => {
  const { address } = props;
  const classes = useStyles();
  const apiKey = 'AIzaSyCzuk_iR7dN1mal82FE47nKUJEkHg9sn9U';

  return (
    <img
      className={classes.root}
      src={`https://maps.googleapis.com/maps/api/staticmap?key=${apiKey}
      &center=${address}
      &markers=size:small|${address}
      &size=472x120
      &zoom=15
      &scale=2`}
      alt="map-location"
    />
  );
};

export default MapWithLocation;
