import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useFirebaseConnect, useFirestoreConnect } from 'react-redux-firebase';
import { generateUserSlices } from '../utils';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'DM Sans',
    fontWeight: '400',
  },
  avatarEventCard: {
    width: '24px',
    height: '24px',
  },
  eventCard: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '24px',
    '& .MuiAvatarGroup-root': {
      marginRight: theme.spacing(1),
    },
    '& p': {
      margin: '0px',
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  containerDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& p': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#333333',
    },
  },
  avatarDetails: {
    width: '40px',
    height: '40px',
  },
}));

const innerTextDisplay = (firstNames) => [
  'Be the first of your friends to respond!',
  `${firstNames[0]}`,
  `${firstNames[0]} and ${firstNames[1]}`,
  `${firstNames[0]}, ${firstNames[1]}, and ${firstNames[2]}`,
  `${firstNames[0]}, ${firstNames[1]}, ${firstNames[2]}, +${
    firstNames.length - 3
  } others`,
];

const PeekRespondents = (props) => {
  const { eventId } = props;
  const { isEventDetails } = props;
  const classes = useStyles();

  useFirebaseConnect([]);
  const profile = useSelector((state) => state.firebase?.profile);
  const authUid = profile?.uid ?? '';

  useFirestoreConnect(['meta']);
  const allUsers = useSelector((state) => state.firestore?.data?.meta?.users);

  useFirestoreConnect(['events']);
  const event = useSelector(
    (state) => state.firestore?.data?.events?.[eventId]
  );
  const goingUsers = event?.goingUsers ?? [];
  const interestedUsers = event?.interestedUsers ?? [];
  const respondents = goingUsers.concat(interestedUsers);
  if (respondents.includes(authUid)) {
    respondents.splice(respondents.indexOf(authUid), 1);
  }

  const friends = profile?.friends ?? [];
  const isFriend = (uid) => friends?.includes(uid);

  const goingFriends = goingUsers.filter(isFriend);
  const interestedFriends = interestedUsers.filter(isFriend);
  const respondedFriends = goingFriends.concat(interestedFriends);
  const friendsSlices = generateUserSlices(respondedFriends, allUsers);

  let numRespondents = isEventDetails
    ? respondedFriends.length
    : respondents.length;
  let innerText;
  if (isEventDetails) {
    const firstNames = friendsSlices.map((slice) => slice.firstName);
    innerText = innerTextDisplay(firstNames)[numRespondents];
  } else {
    if (numRespondents === 0)
      innerText = 'Be the first of your friends to respond!';
    else if (numRespondents === 1) innerText = '1 friend responded';
    else innerText = `${numRespondents} friends responded`;
  }

  const friendsAvatar = friendsSlices
    .sort((a, b) => {
      if (a.photoUrl && b.photoUrl) return 0;
      else if (a.photoUrl) return -1;
      else return 1;
    })
    .slice(0, 3)
    .map((slice) => {
      let initials = '';
      initials += slice?.firstName?.charAt(0);
      initials += slice?.lastName?.charAt(0);
      return (
        <Avatar
          key={slice.uid}
          src={slice.photoUrl}
          alt={`${slice.firstName}-${slice.lastName}-avatar`}
          className={`${
            isEventDetails ? classes.avatarDetails : classes.avatarEventCard
          }`}
        >
          {initials}
        </Avatar>
      );
    });

  return (
    <div className={classes.root}>
      {isEventDetails ? (
        <div className={classes.containerDetails}>
          <div>
            <h3>{`${respondedFriends.length} ${
              respondedFriends.length === 1 ? 'Friend' : 'Friends'
            } Responded`}</h3>
            <p>{innerText}</p>
          </div>
          <div style={{ marginBottom: '7px' }}>
            {friendsAvatar.length > 0 ? (
              <AvatarGroup spacing="small">{friendsAvatar}</AvatarGroup>
            ) : null}
          </div>
        </div>
      ) : (
        <div className={classes.eventCard}>
          {friendsAvatar.length > 0 ? (
            <AvatarGroup spacing="small">{friendsAvatar}</AvatarGroup>
          ) : null}
          <p>{innerText}</p>
        </div>
      )}
    </div>
  );
};

export default PeekRespondents;
