import React, { useState } from 'react';
//import { Router, Switch, Route } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import smile from '../../assets/feather_smile.svg';
import calendar from '../../assets/feather_calendar.svg';
import star from '../../assets/feather_star.svg';
import logout from '../../assets/feather_log-out.svg';
import { Link } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'right',
    margin: theme.spacing(2),
  },
  menuButton: {
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    border: 'none',
  },

  menuActive: {
    visibility: 'visible',

    '& .MuiMenu-list': {
      marginTop: '12px',
    },
  },
  menuInactive: {
    visibility: 'hidden',
  },
  menuItems: {
    textDecoration: 'none',
    fontFamily: 'DM Sans',
    fontWeight: 'bold',
    fontSize: '16px',
    paddingLeft: '4px',

    '& a': {
      color: theme.palette.text.main,
      textDecoration: 'none',
    },

    '& .MuiMenuItem-root': {
      padding: '8px 16px',
      fontWeight: '500',
      textDecoration: 'none',
    },
    '& img': {
      paddingRight: '10px',
    },
  },
  signOut: {
    color: '#C7003A',
  },
}));

const StyledMenu = withStyles({
  paper: {
    top: '64px !important',
    right: '20px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 16px rgba(134, 134, 134, 0.25)',
    borderRadius: '8px',
    width: '192px',
    height: '200px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    transformOrigin={{ vertical: 8, horizontal: 'right' }}
    {...props}
  />
));

const ProfileMenu = (props) => {
  const { photoURL } = props;
  const classes = useStyles();
  const [show, setShow] = useState(null);
  const firebase = useFirebase();
  const signOut = () => {
    firebase.auth().signOut().then(console.log('User signed out.'));
  };

  const showMenu = (event) => {
    setShow(event.currentTarget);
  };

  const hideMenu = () => {
    setShow(null);
  };

  const UserAvatar = () => (
    <Avatar onClick={showMenu} className={classes.menuButton} src={photoURL} />
  );

  return (
    <div className={classes.root}>
      <UserAvatar />
      <StyledMenu
        anchorEl={UserAvatar}
        open={Boolean(show)}
        onClose={hideMenu}
        className={`${show ? classes.menuActive : classes.menuInactive} ${
          classes.menuItems
        }`}
      >
        <Link to="/profile">
          <MenuItem>
            <img src={smile} alt="profile" />
            Profile
          </MenuItem>
        </Link>
        <Link to="/my-events">
          <MenuItem>
            <img src={calendar} alt="events" />
            My Events
          </MenuItem>
        </Link>
        <Link to="/my-interests">
          <MenuItem>
            <img src={star} alt="interests" />
            My Interests
          </MenuItem>
        </Link>
        <MenuItem className={classes.signOut} onClick={signOut}>
          <img src={logout} alt="signout" />
          Sign Out
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

export default ProfileMenu;
