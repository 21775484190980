import React from 'react';
import Chip from '@material-ui/core/Chip';
import { styled } from '@material-ui/core/styles';

const TagChip = styled(({ highlight, ...other }) => <Chip {...other} />)({
  margin: '4px',
  marginLeft: '0px',
  fontSize: '12px',
  borderRadius: '25px',
  color: (props) => (props.highlight ? 'white' : 'black'),
  backgroundColor: (props) => (props.highlight ? '#2f80ed' : '#F2F2F2'),
  '&:focus': {
    backgroundColor: (props) => (props.highlight ? '2F80ED' : 'F2F2F2'),
  },
});

export default TagChip;
