import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sortEventByStartTime } from '../utils';
import EventCard from '../CardCarousel/EventCard';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import arrow from '../CardCarousel/images/Vector.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '324px',
    width: '335px',
  },
  carousel: {
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    padding: '2px',
    width: '331px',
    height: '320px',
  },
  sliderWrap: {
    width: '340px',
    height: '320px',
    marginBottom: '0px',
  },
  arrows: {
    width: '24px',
    height: '24px',
    marginTop: '6.4px',
    border: '0',
    color: '#333333',
    backgroundColor: 'transparent',
  },
  leftArrow: {
    transform: 'scaleX(-1)',
  },
  bottomWrapper: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5.6px',
    '& .carousel__dot-group': {
      alignItems: 'center',
      width: '64px',
    },
    '& .carousel__dot': {
      width: '4px',
      height: '4px',
      margin: '3px',
      backgroundColor: '#E0E0E0',
      borderRadius: '50%',
      border: 'none',
      padding: '0px',
    },
    '& .carousel__dot--selected': {
      backgroundColor: '#FF521E',
      borderRadius: '25px',
      width: '8px',
      height: '4px',
    },
  },
}));

const OrgUpcomingEvents = (props) => {
  const { orgEvents } = props;
  const classes = useStyles();
  sortEventByStartTime(orgEvents);

  return (
    <div className={classes.root}>
      <CarouselProvider
        naturalSlideWidth={200}
        naturalSlideHeight={200}
        totalSlides={orgEvents?.length}
        visibleSlides={1}
        step={1}
        className={classes.container}
      >
        <Slider
          className={classes.container}
          classNameTrayWrap={classes.sliderWrap}
        >
          {orgEvents?.map((item, idx) => (
            <Slide key={item.eventId} index={idx}>
              <EventCard
                key={idx}
                itemId={item.eventId}
                downloadUrl={item.imageUrl}
              />
            </Slide>
          )) ?? []}
        </Slider>
        <div className={classes.bottomWrapper}>
          <ButtonBack className={`${classes.arrows} ${classes.leftArrow}`}>
            <img src={arrow} alt="left-arrow" />
          </ButtonBack>
          <DotGroup />
          <ButtonNext className={classes.arrows}>
            <img src={arrow} alt="right-arrow" />
          </ButtonNext>
        </div>
      </CarouselProvider>
    </div>
  );
};

export default OrgUpcomingEvents;
