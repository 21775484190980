import React from 'react';
import TopNavBar from '../TopNavBar';
import { BigHeading } from '../shared/styles';
import OrgsPickerContainer from '../Onboarding/OrgsPickerContainer';

const EditAcademicOrgs = () => {
  return (
    <div>
      <BigHeading>Academic Orgs</BigHeading>
      <OrgsPickerContainer isDepartment={true} rerouteOnDone="/my-interests" />
    </div>
  );
};

export default EditAcademicOrgs;
