import { makeStyles } from '@material-ui/core';

export const makeOrgDetailsHeader = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '1024px',
    height: '240px',
    borderRadius: '0px 0px 24px 24px',
    color: 'white',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  vignette: {
    width: '100%',
    height: '100%',
    borderRadius: '0px 0px 24px 24px',
    background: `linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0) 0.01%, rgba(51, 51, 51, 0.25) 100%)`,
  },
  orgTitle: {
    position: 'absolute',
    bottom: '24px',
    left: '86px',
    width: '427px',
    '& h1': {
      fontSize: '32px',
      fontWeight: 'bold',
      lineHeight: '36px',
      margin: '0px',
      textAlign: 'left',
      textShadow: '0px 1px 8px rgba(0, 0, 0, 0.5)',
    },
  },
  followButtonDiv: {
    position: 'absolute',
    bottom: '24px',
    right: '88px',
  },
  followButton: {
    height: '36px',
    background: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '400',
    color: '#333333',
    padding: '8px 12px',
    '& .MuiButton-startIcon': {
      marginRight: '4px !important',
    },
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
    '&.MuiButton-root:hover': {
      background: '#FFFFFF',
    },
  },
  follow: {
    width: '85px',
  },
  following: {
    width: '98px',
  },
}));

export const makeOrgDetailsPage = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    '& h2': {
      fontSize: '20px',
      lineHeight: '30px',
      letterSpacing: '0.15px',
      fontWeight: '500',
      marginTop: '24px',
      marginBottom: '8px',
    },
  },
  eventsCarousel: {
    position: 'absolute',
    right: '295px',
    width: '336px',
  },
  shareButton: {
    textAlign: 'center',
    marginTop: '50px',
    '& .MuiButton-root': {
      color: '#2F80ED ! important',
      textTransform: 'none',
    },
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    textAlign: 'left',
    paddingLeft: '296px',
    width: '472px',
  },
  footer: {
    marginBottom: '48px',
  },
}));
