import React from 'react';
import { makeOrgDetailsHeader as useStyles } from './styles';
import { useFirestore } from 'react-redux-firebase';
import ClampLines from 'react-clamp-lines';
import { Button } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';

const Header = (props) => {
  const { org, uid } = props;
  const classes = useStyles();
  const firestore = useFirestore();
  let isFollowing;
  let buttonText, startIcon;
  let disabled;
  if (!uid) disabled = true;

  const followHandler = () => async () => {
    try {
      if (isFollowing) {
        await firestore.update(
          { collection: 'organizations', doc: org.orgId },
          { followers: firestore.FieldValue.arrayRemove(uid) }
        );
        await firestore.update(
          { collection: 'users', doc: uid },
          { orgs: firestore.FieldValue.arrayRemove(org.orgId) }
        );
      } else {
        await firestore.update(
          { collection: 'organizations', doc: org.orgId },
          { followers: firestore.FieldValue.arrayUnion(uid) }
        );
        await firestore.update(
          { collection: 'users', doc: uid },
          { orgs: firestore.FieldValue.arrayUnion(org.orgId) }
        );
      }
    } catch (error) {
      console.error('Something went wrong with the Follow Button');
      console.error(error);
    }
  };

  if (org?.followers?.includes(uid)) {
    isFollowing = true;
    buttonText = 'Following';
    startIcon = <DoneIcon />;
  } else {
    isFollowing = false;
    buttonText = 'Follow';
    startIcon = <AddIcon />;
  }

  return (
    <div
      className={classes.root}
      style={{ backgroundImage: `url(${org?.imageUrl})` }}
    >
      <div className={classes.vignette}>
        <div className={classes.orgTitle}>
          <ClampLines
            text={org?.name ?? 'Loading...'}
            lines={4}
            innerElement="h1"
            buttons={false}
          />
        </div>
        <div className={classes.followButtonDiv}>
          {!disabled ? (
            <Button
              disabled={disabled}
              onClick={followHandler()}
              startIcon={startIcon}
              className={`${classes.followButton} ${
                isFollowing ? classes.following : classes.follow
              }`}
            >
              {buttonText}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
