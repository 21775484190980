import React, { useState } from 'react';
import styled from 'styled-components';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { MAX_PHOTO_SIZE, MB_TO_BYTES } from '../shared/constants';

const StyledUploadButton = styled.div`
  position: relative;

  #upload-button {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  #upload-button-label {
    cursor: pointer;
    color: #2f80ed;
  }

  #upload-alert-text {
    position: absolute;
    top: 25px;
    left: 0px;
    width: max-content;
    line-height: 100%;
  }
`;

const UploadPhotoButton = (props) => {
  const { uid } = props;
  const firebase = useFirebase();
  const firestore = useFirestore();

  const states = {
    INITIAL: 'INITIAL',
    UPLOADING: 'UPLOADING',
    SIZE_EXCEED: 'SIZE_EXCEED',
    UPLOAD_FAIL: 'UPLOAD_FAIL',
    UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  };

  const [state, setState] = useState(states.INITIAL);

  const imageUrlUpdate = (url) => {
    firestore
      .collection('users')
      .doc(uid)
      .update({
        photoUrl: url,
      })
      .then(() => {
        console.log('Successfully updated avatar!');
      })
      .catch((error) => {
        console.error('Error updating photo', error);
      });
  };

  const handleUploadFile = async (event) => {
    const inputImg = event.target.files[0];
    if (!inputImg) return;

    if (inputImg.size / MB_TO_BYTES > MAX_PHOTO_SIZE) {
      setState(states.SIZE_EXCEED);
      return;
    }

    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(`users/${uid}`);

    try {
      setState(states.UPLOADING);
      await imageRef.put(inputImg);
      const imageUrl = await imageRef.getDownloadURL();
      await imageUrlUpdate(imageUrl);
    } catch (error) {
      setState(states.UPLOAD_FAIL);
      console.error('Error uploading image: ', error);
    }

    setState(states.UPLOAD_SUCCESS);
  };

  let alertText;
  switch (state) {
    case states.SIZE_EXCEED:
      alertText = 'File size exceeds 5 MB';
      break;
    case states.UPLOADING:
      alertText = 'Uploading...';
      break;
    case states.UPLOAD_FAIL:
      alertText = 'Error occurred';
      break;
    default:
      alertText = null;
      break;
  }

  return (
    <StyledUploadButton>
      <form onSubmit={handleUploadFile}>
        <input
          onChange={handleUploadFile}
          type="file"
          id="upload-button"
          accept="image/*"
        />
        <label htmlFor="upload-button" id="upload-button-label">
          Change Photo
        </label>
      </form>
      <div id="upload-alert-text">{alertText}</div>
    </StyledUploadButton>
  );
};

export default UploadPhotoButton;
