import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFirestoreConnect, useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { makeOrgDetailsPage as useStyles } from './styles';
import { generateOneUserSlice } from '../utils';
import TopNavBar from '../TopNavBar';
import Header from './Header';
import PeekFollowers from './PeekFollowers';
import OrgUpcomingEvents from './OrgUpcomingEvents';
import ShareButton from '../shared/ShareButton';
import SocialMedia from './SocialMedia';
import TagChip from '../shared/TagChip';
import UserCard from '../SocialComponents/UserCard';
import UnconnectedUserCard from '../SocialComponents/UnconnectedUserCard';
import MapWithLocation from '../shared/MapWithLocation';

const LeadershipCards = (props) => {
  const { contact, leaders } = props;
  const { allUsers = [], hasOldFormat } = props;

  let children;
  if (hasOldFormat && contact)
    children = <UnconnectedUserCard fullName={contact?.name} />;
  else if (!hasOldFormat && leaders.length > 0) {
    children = leaders.map((str) => {
      let slice = generateOneUserSlice(str, allUsers);
      if (slice)
        return <UserCard key={slice.uid} uid={slice.uid} disableLink />;
      else return <UnconnectedUserCard key={str} fullName={str} />;
    });
  } else children = <p>None</p>;
  return <div>{children}</div>;
};

const OrganizationDetails = () => {
  const { id: orgId } = useParams();
  const classes = useStyles();

  useFirestoreConnect(['organizations']);
  const organizations = useSelector(
    (state) => state.firestore?.data?.organizations
  );
  const org = organizations?.[orgId];
  const address = org?.location?.address;
  const leaders = org?.leadership ?? null;
  const hasOldFormat = !leaders;
  const contact = org?.contact;
  const socialMedia = org?.socialMedia ?? {};
  const tags = org?.attributes ?? [];
  const orgUrl = `${window.location.origin}/organizations/${orgId}`;

  useFirestoreConnect('events');
  const eventsMap = useSelector((state) => state.firestore?.data?.events ?? {});
  const allEventObject = [];
  const orgEventList = [];
  if (eventsMap) {
    Object.keys(eventsMap).forEach((eventId) =>
      allEventObject.push(eventsMap[eventId])
    );
  }
  for (const event of allEventObject) {
    if (event?.owner?.id === org?.orgId) {
      orgEventList.push(event?.eventId);
    }
  }
  const orgEvents = orgEventList.map((id) => eventsMap[id]);

  useFirestoreConnect(['meta']);
  const allUsers = useSelector((state) => state.firestore?.data?.meta?.users);

  useFirebaseConnect([]);
  const profile = useSelector((state) => state.firebase?.profile);
  const uid = profile?.uid ?? '';

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Header org={org} uid={uid} />
      </div>

      <div className={classes.eventsCarousel}>
        <h2 style={{ paddingLeft: '2px' }}>Upcoming Events</h2>
        {orgEventList.length > 0 ? (
          <OrgUpcomingEvents orgEvents={orgEvents} />
        ) : (
          <p>No upcoming events found.</p>
        )}
        <div className={classes.shareButton}>
          <ShareButton
            title={org?.name}
            url={orgUrl}
            colorStroke="#2F80ED"
            label="Share This Page"
          />
        </div>
      </div>

      <div className={classes.content}>
        <div>
          <PeekFollowers
            orgId={orgId}
            org={org}
            profile={profile}
            allUsers={allUsers}
          />
        </div>
        <div>
          <h2>Introduction</h2>
          <p>{org?.description ?? 'Loading..'}</p>
        </div>

        {address ? (
          <div>
            <h2>Location</h2>
            <p>{address}</p>
            <MapWithLocation
              style={{ borderRadius: '5px' }}
              address={address}
            />
          </div>
        ) : null}

        <div>
          <h2>{org?.department ? 'Administrative Assistant' : 'Leadership'}</h2>
          <LeadershipCards
            hasOldFormat={hasOldFormat}
            contact={contact}
            leaders={leaders}
            allUsers={allUsers}
          />
        </div>

        <div>
          <h2>Contact Detail</h2>
          <p>{contact ? contact?.email : contact?.name ?? ''}</p>
        </div>

        <div>
          <h2>Social Media</h2>
          {org?.socialMedia ? (
            <SocialMedia socialMedia={socialMedia} />
          ) : (
            <p>None</p>
          )}
        </div>

        {tags.length > 0 ? (
          <div>
            <h2>Related Tags</h2>
            {tags?.map((item) => (
              <Link
                to={`/search/{"tags":["${item}"]}`}
                style={{ textDecoration: 'none' }}
                key={item}
              >
                <TagChip
                  style={{ height: '36px', fontSize: '14px' }}
                  label={item}
                />
              </Link>
            )) ?? null}
          </div>
        ) : null}
      </div>

      <div className={classes.footer} />
    </div>
  );
};

export default OrganizationDetails;
