import React from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { StyledEventCard, useStyles } from './styles';
import RibbonArray from '../shared/RibbonArray';
import EngagementButtonContainer from '../shared/EngagementButtonContainer';
import ShareButton from '../shared/ShareButton';
import EventDateTime from '../shared/EventDateTime';
import PeekRespondents from '../shared/PeekRespondents';
import TagChip from '../shared/TagChip';
import PlaceholderImage from '../../assets/placeholder.jpg';
import ClampLines from 'react-clamp-lines';
import { Link } from 'react-router-dom';

const EventCard = (props) => {
  const { itemId, downloadUrl } = props;
  const classes = useStyles();
  useFirestoreConnect(['events']);
  const item = useSelector(
    (state) => state.firestore?.data?.events[itemId],
    _.isEqual
  );

  const { eventId: id, title, location } = item;

  const eventURL = `${window.location.origin}/events/${id}`;

  const typeEvent = location?.type ?? 'web';
  let locationName;
  if (location?.name) locationName = location.name;
  else if (typeEvent !== 'place') locationName = 'Online';
  else locationName = ' ';

  let eventTags = item?.filters?.attributes ?? [];
  eventTags = eventTags.length < 3 ? eventTags : eventTags.slice(0, 3);

  let ribbonArray = [location?.type];
  if (item?.visibility === 'organization') ribbonArray.push(item?.visibility);

  const src = downloadUrl ? downloadUrl : PlaceholderImage;

  return (
    <StyledEventCard key={itemId}>
      <EngagementButtonContainer
        event={item}
        cardType="EventCard"
        locationType={location.type}
      />
      <Link
        to={`/events/${id}`}
        style={{ textDecoration: 'none', color: '#000000' }}
      >
        <div
          className={classes.header}
          style={{
            backgroundImage: `url(${src})`,
          }}
        >
          <div className={classes.vignette}>
            <ShareButton title={title} url={eventURL} type="eventCard" />
            <RibbonArray ribbonArray={ribbonArray}></RibbonArray>
            <div className={classes.eventTitle}>
              <ClampLines
                text={title}
                lines={2}
                innerElement="h1"
                buttons={false}
              />
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.timeAndLocation}>
            <EventDateTime
              className={classes.time}
              startTime={item?.time.start.toDate().toString()}
            />
            <ClampLines
              text={locationName}
              lines={1}
              innerElement="p"
              buttons={false}
            />
          </div>
          <div className={classes.peekRespondents}>
            <PeekRespondents eventId={itemId} disableOnClick />
          </div>
          <div>
            {eventTags?.map((tag) => (
              <TagChip
                className={classes.tag}
                key={tag}
                label={tag}
                size="small"
              ></TagChip>
            ))}
          </div>
        </div>
      </Link>
    </StyledEventCard>
  );
};

export default EventCard;
