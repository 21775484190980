import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  chip: {
    margin: '2px',
  },
  loading: {
    fontStyle: 'italic',
  },
}));

const Suggestions = (props) => {
  const { tagValues, setTagValues, setTags, rendering } = props;
  const classes = useStyles();
  const handleFromSuggestions = (tag) => {
    const previousTags = [...tagValues.currentTags];
    if (previousTags.indexOf(tag) === -1 && previousTags.length < 5) {
      const updatedTags = previousTags.concat(tag);
      const updatedSuggestions = [...tagValues.suggested];
      updatedSuggestions.splice(updatedSuggestions.indexOf(tag), 1);
      const updatedFromSuggestions = tagValues.addedFromSuggestions.concat(tag);
      setTagValues({
        ...tagValues,
        suggested: updatedSuggestions,
        addedFromSuggestions: updatedFromSuggestions,
        currentTags: updatedTags,
      });
      setTags(updatedTags);
    }
  };

  const suggested = tagValues.suggested || [];
  const upperTags = tagValues.currentTags.map((x) => x.toUpperCase());

  const Chips = () => {
    const suggestions = new Set();
    suggested.forEach((tag) => {
      if (upperTags.indexOf(tag.toUpperCase()) === -1) {
        suggestions.add(tag);
      }
    });
    const chips = new Set();
    suggestions.forEach((chip) => {
      chips.add(
        <Chip
          className={classes.chip}
          key={chip}
          label={chip}
          onClick={() => {
            handleFromSuggestions(chip);
          }}
        ></Chip>
      );
    });
    return chips;
  };

  return (
    <div className={classes.root}>
      <Chips />
      {rendering ? (
        <div className={classes.loading}>
          Loading Suggested Tags...{' '}
          <CircularProgress size={12}></CircularProgress>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Suggestions;
