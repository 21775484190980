import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase';
import TopNavBar from '../TopNavBar';
import CardCarousel from '../CardCarousel';
import { useFirebaseConnect, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { sortEventByStartTime } from '../utils';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import TagChip from '../shared/TagChip';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import EventCardCarouselSkeleton from '../shared/EventCardCarouselSkeleton';
import TagsSkeleton from '../shared/TagsSkeleton';

const useStyles = makeStyles(() => ({
  root: {},
  bigHeading: {
    textAlign: 'left',
    paddingLeft: '172px',
    fontSize: '32px',
    lineHeight: '36px',
    color: '#FF521E',
    marginTop: '48px',
    marginBottom: '0px',
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginLeft: '173px',
    marginRight: '185px',
    '& h2': {
      textAlign: 'left',
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '30px',
      letterSpacing: '0.15px',
      marginTop: '24px',
      marginBottom: '16px',
    },
  },
  moreButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: '#2F80ED',
    width: '33px',
    height: '20px',
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '24px',
    marginBottom: '16px',
    textTransform: 'none',
  },
  popularTags: {
    marginLeft: '173px',
    marginRight: '185px',
    textAlign: 'left',
    fontSize: '14px',
  },
  footer: {
    height: '48px',
  },
}));

const SearchResults = (props) => {
  const classes = useStyles();
  const { query: queryString } = useParams();
  const { query, tags } = JSON.parse(queryString);
  const [events, setEvents] = useState([]);
  const hasQueried = useRef(false);
  useFirestoreConnect('events');
  const eventMap = useSelector((state) => state.firestore?.data?.events) ?? {};

  useEffect(() => {
    const getEventCarousels = async () => {
      const eventsCarousel = firebase.functions().httpsCallable('queryEvents');
      try {
        const results = await eventsCarousel({
          query,
          filters: { tags },
        });
        setEvents(results?.data ?? []);
        hasQueried.current = true;
      } catch (error) {
        console.error(error);
      }
    };
    getEventCarousels();
  }, [query, tags]);

  const resultEvents = events.map((id) => eventMap[id]);

  return (
    <div className={classes.root}>
      <h1 className={classes.bigHeading}>Search</h1>
      <section>
        <div className={classes.titleSection}>
          <h2>Showing results for: {query ?? tags[0]}</h2>
        </div>
        {!hasQueried.current ? (
          <EventCardCarouselSkeleton />
        ) : resultEvents.length === 0 ? (
          <h3> No event matches your query. Please try again! </h3>
        ) : (
          <CardCarousel events={resultEvents} />
        )}
      </section>
      <section className={classes.footer} />
    </div>
  );
};

export default SearchResults;
