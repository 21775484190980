import React from 'react';

const PrivacyPolicy = () => {
  window.location.replace(
    'https://firebasestorage.googleapis.com/v0/b/obielocal-1541269219020.appspot.com/o/info%2Fprivacy-policy.pdf?alt=media&token=122a4e41-7d8f-4130-a658-0ebb62532c81'
  );
  return <div>Redirecting...</div>;
};

export default PrivacyPolicy;
