import React from 'react';
//import { Router, Switch, Route } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/twine-logo.png';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ProfileMenu from './ProfileMenu';
import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    display: 'flex',
    justifyContent: 'space-between',
    height: '72px',
    width: '100%',
    left: '0px',
    top: '0px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 4px rgba(134, 134, 134, 0.25)',
    zIndex: '99 !important',
  },
  logo: {
    width: '40px',
    height: '40px',
    margin: theme.spacing(2),
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
    width: '504px',
    height: '40px',
    margin: theme.spacing(2),
  },
  searchIcon: {
    height: '16px',
    width: '16px',
    padding: theme.spacing(1),
  },
  inputRoot: {
    color: 'inherit',
  },
  inputBar: {
    padding: theme.spacing(1),
    width: '100%',
  },
}));

const TopNavBar = React.memo(() => {
  const classes = useStyles();

  useFirebaseConnect([]);
  const photoURL = useSelector((state) => state.firebase?.profile?.photoUrl);
  const history = useHistory();

  const onChange = (event) => {
    const query = event.target.value;
    if (query.length >= 3) {
      history.push(`/search/{"query":"${query}"}`);
    } else {
      history.push('/');
    }
  };

  return (
    <div className={classes.root}>
      <Link to="/">
        <img
          src={logo}
          alt="Twine-logo"
          className={classes.logo}
          onClick={() => {}}
        />
      </Link>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search"
          classes={{
            root: classes.inputRoot,
            input: classes.inputBar,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={onChange}
        />
      </div>

      <ProfileMenu photoURL={photoURL} />
    </div>
  );
});

export default TopNavBar;
