import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const BigPrimaryButton = withStyles({
  root: {
    borderRadius: '28px',
    padding: '16px 24px',
    textTransform: 'none',
    fontSize: '16px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  },
})(Button);

export default BigPrimaryButton;
