import { withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const BigHeading = withTheme(styled.h1`
  text-align: left;
  padding-left: 200px;
  font-size: 32px;
  line-height: 36px;
  color: ${(props) => props.theme.palette.primary.main};
  margin-top: 48px;
  margin-bottom: 0px;
`);

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 24px auto 16px 200px;
  margin-right: ${(props) => props.marginRight};
  h2 {
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.15px;
    margin: 0;
  }
`;

export const Footer = styled.div`
  margin-bottom: 48px;
`;
