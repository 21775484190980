import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TextField, FormControl, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '504px',
    textAlign: 'left',
    margin: 'auto',
    '& .MuiFormControl-root': {
      width: '100%',
      margin: `${theme.spacing(1)}px 0px 0px 0px`,
      textAlign: 'left',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#F2F2F2',
      width: '244px !important',
      height: '40px',
      borderRadius: '8px',
      marginRight: '16px',
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.5),
    },
    '& label': {
      display: 'block',
      margin: `${theme.spacing(3)}px 0px 0px 0px`,
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: '500',
    },
    '& .MuiFilledInput-underline:before': {
      visibility: 'hidden',
    },
    '& .MuiFilledInput-underline:after': {
      visibility: 'hidden',
    },
  },
  nameInput: {
    display: 'flex',
  },
}));

const EditProfileForm = (props) => {
  const { handleChange, formState } = props;
  const classes = useStyles();

  const currentYear = new Date().getFullYear();
  const classYearOptions = [];
  for (let i = currentYear; i < currentYear + 5; i++) {
    classYearOptions.push(i);
  }

  return (
    <form className={classes.root}>
      <div className={classes.nameInput}>
        <div>
          <label>First Name</label>
          <TextField
            id="signup-firstName"
            variant="filled"
            onChange={(e) => handleChange(e, 'firstName')}
            value={formState.firstName}
            InputProps={{ disableUnderline: true }}
          />
        </div>

        <div>
          <label>Last Name</label>
          <TextField
            id="signup-lastName"
            variant="filled"
            onChange={(e) => handleChange(e, 'lastName')}
            value={formState.lastName}
            InputProps={{ disableUnderline: true }}
          />
        </div>
      </div>

      <label>Class Year</label>
      <FormControl variant="filled">
        <Select
          id="class-year-select-filled"
          labelId="class-year-select-filled-label"
          onChange={(e) => handleChange(e, 'classYear')}
          value={formState.classYear}
          InputProps={{ disableUnderline: true }}
        >
          <MenuItem value="">
            <em>Choose</em>
          </MenuItem>
          {classYearOptions.map((year, idx) => (
            <MenuItem key={idx} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </form>
  );
};

EditProfileForm.propTypes = {
  formState: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    classYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default EditProfileForm;
