import React from 'react';
import { ReactComponent as TagSkeleton } from '../../assets/tag-skeleton.svg';

const TagsSkeleton = () => (
  <div>
    <TagSkeleton />
    <TagSkeleton />
    <TagSkeleton />
  </div>
);

export default TagsSkeleton;
