import Card from '@material-ui/core/Card';
import { makeStyles, styled } from '@material-ui/core/styles';

export const StyledEventCard = styled(Card)({
  height: '320px',
  width: '331px',
  borderRadius: '5px',
});

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '240px',
    position: 'relative',
  },
  header: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    height: '175px',
    position: 'relative',
    color: 'white',
  },
  vignette: {
    width: '100%',
    height: '100%',
    background: `linear-gradient(180deg, rgba(51, 51, 51, 0.25) 0%, rgba(51, 51, 51, 0) 100%),
    linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0) 0.01%, rgba(51, 51, 51, 0.25) 100%)`,
  },
  eventTitle: {
    position: 'absolute',
    bottom: '0px',
    left: '16px',

    '& h1': {
      textAlign: 'left',
      fontSize: '24px',
      lineHeight: '32px',
      textShadow: '0px 1px 8px rgba(0, 0, 0, 0.5)',
    },
  },
  content: {
    margin: '17px 16px 16px 16px',
    textAlign: 'left',
  },
  timeAndLocation: {
    '& p': {
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '500',
      margin: '0px',
      '& strong': {
        color: '#C7003A',
      },
    },
  },
  peekRespondents: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    fontColor: '#4F4F4F',
    marginTop: '6px',
    marginBottom: '10px',
  },
  tag: {
    height: '24px',
  },
}));
