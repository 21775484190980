import { makeStyles } from '@material-ui/core';

export const makeUserCardStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '236px',
    height: '64px',
    marginTop: theme.spacing(1),
    background: '#FFFFFF',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    '& .MuiCardActionArea-root': {
      height: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    '& .MuiAvatar-root': {
      margin: '0px 8px',
    },
  },
  cardNoActionArea: {
    height: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '0px',
    },
  },
  content: {
    flexGrow: '2',
    width: '66%',
    padding: '0px 8px',
    '& h4, p': {
      display: 'block',
      margin: '0px',
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '500',
      color: '#333333',
    },
    '& p': {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '-0.25px',
      color: '#828282',
    },
  },
  actions: {
    display: 'inline-flex',
    '& .MuiIconButton-root': {
      padding: '8px',
    },
  },
}));
