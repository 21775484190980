import React, { useState, useEffect } from 'react';
import 'date-fns';
import 'firebase/functions';
import Suggestions from './Suggestions';
import TagInput from './TagInput';
import AddedTags from './AddedTags';

const Tags = (props) => {
  const originalSuggested = props.suggestions;
  const { setTags, metaTags, rendering } = props;
  const [tagValues, setTagValues] = useState({
    currentTags: [],
    addedFromBox: [],
    addedFromSuggestions: [],
    optionList: metaTags || [],
    suggested: originalSuggested,
    currentText: '',
    constantOptions: metaTags || [],
    suggestionsRendered: false,
    changed: false,
  });
  useEffect(() => {
    setTagValues((t) => {
      return {
        ...t,
        optionList: metaTags,
        constantOptions: metaTags,
        suggested: originalSuggested,
      };
    });
  }, [metaTags, originalSuggested]);

  return (
    <div>
      <TagInput
        tagValues={tagValues}
        setTagValues={setTagValues}
        setTags={setTags}
      />
      <AddedTags
        tagValues={tagValues}
        setTagValues={setTagValues}
        fullSuggested={originalSuggested}
      />
      <Suggestions
        tagValues={tagValues}
        setTagValues={setTagValues}
        rendering={rendering}
        setTags={setTags}
      />
    </div>
  );
};

export default Tags;
