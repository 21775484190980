import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const RemovePhotoButton = (props) => {
  const { uid } = props;
  const firestore = useFirestore();
  const classes = useStyles();

  const handleRemoveAvatar = () => {
    firestore
      .collection('users')
      .doc(uid)
      .update({
        photoUrl: null,
      })
      .then(() => {
        console.log('Successfully removed!');
      })
      .catch((error) => {
        console.error('Error removing photo: ', error);
      });
  };

  return (
    <div className={classes.root} onClick={handleRemoveAvatar}>
      Remove Photo
    </div>
  );
};

export default RemovePhotoButton;
