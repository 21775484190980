import React from 'react';
import { useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import TopNavBar from '../TopNavBar';
import EditProfile from './EditProfile';
import EditProfilePicture from './EditProfilePicture';

const MyProfile = () => {
  useFirebaseConnect([]);
  const profile = useSelector((state) => state.firebase?.profile);

  return (
    <div>
      <EditProfilePicture profile={profile} />
      <EditProfile profile={profile} />
    </div>
  );
};

export default MyProfile;
