import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ReactComponent as JoinZoomIcon } from '../shared/images/desktop.svg';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-even',
  },
  button: {
    width: '149px',
    height: '64px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '400',
  },
  buttonDiv: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    paddingLeft: '30px',
    fontSize: '14px',
    lineHeight: '20px',
    wordBreak: 'break-all',
    '& p': {
      color: '#333333',
    },
    '& a': {
      textDecoration: 'none',
      color: '#2F80ED',
    },
  },
}));

const JoinButtons = (props) => {
  const { joinLink = '', isNonUser } = props;
  const disabled = isNonUser || !joinLink;
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Button
          disabled={disabled}
          href={'' + joinLink}
          className={classes.button}
        >
          <div className={classes.buttonDiv}>
            <JoinZoomIcon />
            Join Zoom
          </div>
        </Button>

        <div className={classes.link}>
          <p>Or use this link:</p>
          <a href={`${joinLink}`}>{joinLink}</a>
        </div>
      </div>

      {isNonUser ? (
        <p>
          <span style={{ fontStyle: 'italic' }}>
            You'll need to sign in to view the link to this event.
          </span>
        </p>
      ) : null}
    </div>
  );
};

JoinButtons.propTypes = {
  joinLink: PropTypes.string,
  isNonUser: PropTypes.bool,
};

export default JoinButtons;
